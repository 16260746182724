import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  IconButton,
  Box,
  Typography,
  InputAdornment,
  Button,
  Input,
  InputLabel,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { resetPassword } from "../../redux/actions/smsActions";
import { withRouter } from "../../utils/withRouter";
import { useTranslation } from 'react-i18next'

function Password(props) {
  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <Input
        margin="dense"
        required
        fullWidth
        id={props.id}
        name={props.id}
        placeholder={props.placeholder}
        type={
          props.type !== "password"
            ? props.type
            : showPassword
              ? "password"
              : "text"
        }
        autoComplete={props.id}
        startAdornment={
          <InputAdornment position="start">
            <LockIcon color="primary" />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        sx={{ mt: 1 }}
      />
    </Box>
  );
}

const ForgotPassword = ({ resetPassword, router }) => {
  const { t } = useTranslation()

  const { token } = useParams();

  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get("password") !== data.get("password2")) {
      setError("Passwords not matched");
      return;
    }

    const passData = {
      token: token,
      password: data.get("password"),
      password2: data.get("password2"),
    };

    resetPassword(passData, router.navigate);
  };

  return (
    <div>
      <>
        <Typography
          component="h1"
          variant="h5"
          align="center"
          color="text.secondary"
        >
          {t('cp_caption')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Password
            id="password"
            type="password"
            label={t('label_np')}
            placeholder={t('ph_password')}
          />
          <Password
            id="password2"
            type="password"
            label={t('label_rp')}
            placeholder={t('label_rp')}
          />
          {error && <p className="text-red-500 text-[14px]">{error}</p>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 10, mb: 2 }}
            className="button1"
          >
            {t('btn_change')}
          </Button>
        </Box>
      </>
    </div>
  );
};

ForgotPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { resetPassword })(
  withRouter(ForgotPassword)
);
