import * as React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { withRouter } from '../../utils/withRouter'

import { SwalAlert } from '../../swal/SwalAlert'
import 'sweetalert2/src/sweetalert2.scss'

import { Button, Input, InputLabel, Box } from '@mui/material'

import { support } from '../../redux/actions/supportActions'
import { useTranslation } from 'react-i18next'

const Support = ({ support, router }) => {
  const { t } = useTranslation()

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    const swalWithBootstrapButton = SwalAlert.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
    })

    swalWithBootstrapButton
      .fire({
        title: 'Would you like to support us?',
        text: 'We will reply you asap',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, Support now!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (data.get('subject') === '' || data.get('description') === '')
            swalWithBootstrapButton.fire(
              'Warning!',
              'Please fill the form',
              'warning',
            )
          else {
            support(
              {
                subject: data.get('subject'),
                description: data.get('description'),
              },
              router.navigate,
            )
            swalWithBootstrapButton.fire(
              'Success!',
              'Sent successfully!',
              'success',
            )
          }
        } else if (result.dismiss === SwalAlert.DismissReason.cancel) {
          swalWithBootstrapButton.fire(
            'Cancelled',
            'Please support us :)',
            'error',
          )
        }
      })
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
      <InputLabel htmlFor="subject" sx={{ mt: 2 }}>
        {t('label_support')}
      </InputLabel>
      <Input
        margin="dense"
        required
        fullWidth
        id="subject"
        name="subject"
        placeholder="subject"
        type="text"
        autoComplete="subject"
        sx={{ mt: 1 }}
      />
      <InputLabel htmlFor="description" sx={{ mt: 2 }}>
        {t('label_description')}
      </InputLabel>
      <Input
        margin="dense"
        required
        fullWidth
        multiline
        rows={4}
        id="description"
        name="description"
        placeholder={t('ph_description')}
        type="text"
        autoComplete="description"
        sx={{ mt: 2 }}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 10 }}
        className="button1"
      >
        {t('btn_submit')}
      </Button>
    </Box>
  )
}

Support.propTypes = {
  support: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { support })(withRouter(Support))
