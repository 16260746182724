import axios from 'axios'
import { GET_ERRORS, GET_USER } from '../types'

export const getUserById = () => (dispatch) => {
  axios
    // .get('http://localhost:5000/api/users/get_user')
    .get('https://api.gold-bit.io/api/users/get_user')
    .then((res) =>
      dispatch({
        type: GET_USER,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: {},
      }),
    )
}

export const changePassword = (data, navigate) => (dispatch) => {
  axios
    // .post('http://localhost:5000/api/users/set-password', data)
    .post('https://api.gold-bit.io/api/users/set-password', data)
    .then((res) => navigate('/home'))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: {},
      }),
    )
}
