import * as React from 'react';

import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
    IconButton,
    Box,
    Typography,
    InputAdornment,
    Input,
    InputLabel,
    Button,
} from "@mui/material";
import { ContentPasteGo, EmailOutlined, HouseOutlined, Person2Outlined, PhoneIphone, Visibility, VisibilityOff } from "@mui/icons-material";

import { SwalAlert } from '../../swal/SwalAlert';
import 'sweetalert2/src/sweetalert2.scss'

import { contactUs } from '../../redux/actions/supportActions';

function Password(props) {
    const [showPassword, setShowPassword] = React.useState(true);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box sx={{ mt: 2 }}>
            <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
            {props.id === 'referralCode' ? <Input
                margin="dense"
                required
                fullWidth
                type={
                    props.type !== "password"
                        ? props.type
                        : showPassword
                            ? "password"
                            : "text"
                }
                id={props.id}
                name={props.id}
                placeholder={props.placeholder}
                value={props.value}
                autoComplete={props.id}
                startAdornment={
                    <InputAdornment position="start">
                        {props.startAdornment}
                    </InputAdornment>
                }
                endAdornment={
                    props.endAdornment ? (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        <></>
                    )
                }
                sx={{ mt: 1 }}
            /> : <Input
                margin="dense"
                required
                fullWidth
                type={
                    props.type !== "password"
                        ? props.type
                        : showPassword
                            ? "password"
                            : "text"
                }
                id={props.id}
                name={props.id}
                placeholder={props.placeholder}
                autoComplete={props.id}
                startAdornment={
                    <InputAdornment position="start">
                        {props.startAdornment}
                    </InputAdornment>
                }
                readOnly={props.id === 'referralCode' ? true : false}
                endAdornment={
                    props.endAdornment ? (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        <></>
                    )
                }
                sx={{ mt: 1 }}
            />}

        </Box>
    );
}

const ContactUs = ({ contactUs }) => {
    const handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const userData = {
            email: data.get("email"),
            firstName: data.get("firstName"),
            lastName: data.get("lastName"),
            phone: data.get("phone"),
            address: data.get("address"),
            content: data.get("content"),
        };

        const swalWithBootstrapButton = SwalAlert.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: true,
        })

        swalWithBootstrapButton
            .fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, Support now!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    if (!data.get("email") || !data.get("firstName") || !data.get("lastName") || !data.get("phone") || !data.get("address") || !data.get("content"))
                        swalWithBootstrapButton.fire(
                            'Error!',
                            'Oops, Fill the form',
                            'error',
                        )
                    else {
                        await contactUs(userData);
                        swalWithBootstrapButton.fire(
                            'Congratulations!',
                            'We will contact you soon!',
                            'success',
                        )
                    }
                } else if (result.dismiss === SwalAlert.DismissReason.cancel) {
                    swalWithBootstrapButton.fire(
                        'Cancelled',
                        'Feel free to contact us :)',
                        'error',
                    )
                }
            })
    };

    return (
        <Box>
            <Typography
                component="h1"
                variant="h5"
                align="center"
                color="text.secondary"
            >
                Contact Us
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <Password
                    id="firstName"
                    type="text"
                    label="First Name"
                    placeholder="Enter your first name"
                    startAdornment={<Person2Outlined color="primary" />}
                    endAdornment={false}
                />
                <Password
                    id="lastName"
                    type="text"
                    label="Last Name"
                    placeholder="Enter your last name"
                    startAdornment={<Person2Outlined color="primary" />}
                    endAdornment={false}
                />
                <Password
                    id="email"
                    type="email"
                    label="Email Address"
                    placeholder="Enter your email address"
                    startAdornment={<EmailOutlined color="primary" />}
                    endAdornment={false}
                />
                <Password
                    id="phone"
                    label="Telephone"
                    type="text"
                    placeholder="Enter your phone number"
                    startAdornment={<PhoneIphone color="primary" />}
                    endAdornment={false}
                />
                <Password
                    id="address"
                    label="Address"
                    type="text"
                    placeholder="Enter your home address"
                    startAdornment={<HouseOutlined color="primary" />}
                    endAdornment={false}
                />
                <Password
                    id="content"
                    label="Content"
                    type="text"
                    placeholder="Write down what you support"
                    startAdornment={<ContentPasteGo color="primary" />}
                    endAdornment={false}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    className="button1"
                >
                    Submit
                </Button>
            </Box>
        </Box>);
};

ContactUs.propTypes = {
    contactUs: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
    contactUs
})(ContactUs);