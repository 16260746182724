import axios from 'axios'
import { GET_ERRORS } from '../types'

export const support = (data, navigate) => (dispatch) => {
  axios
    // .post('http://localhost:5000/api/support', data)
    .post('https://api.gold-bit.io/api/support', data)
    .then((res) => navigate('/home'))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }),
    )
}

export const contactUs = (data) => {
  axios
    .post("https://api.gold-bit.io/api/support/contactUs", data)
    .then((res) => console.log(res))
    .catch((err) => console.log(err))
}