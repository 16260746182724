import axios from "axios";
import { GET_ERRORS, GET_NOTIFICATIONS } from "../types";

export const getNotifications = () => (dispatch) => {
  axios
    // .get("http://localhost:5000/api/notification")
    .get("https://api.gold-bit.io/api/notification")
    .then((res) =>
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
