import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
// Material UI
import { Button, Box, Typography, Tab, Tabs } from "@mui/material";
// Material UI Icon
import GroupIcon from "@mui/icons-material/Group";

import {
  getNetworkStats,
  getStatsByUser,
} from "../../redux/actions/analyzeActions";
import { getTokenInfo } from "../../redux/actions/propertyActions";
import { getTeamInfo } from "../../redux/actions/teamActions";
import { getRewards } from "../../redux/actions/stakeActions";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MyComponent(props) {
  return (
    <Box
      sx={{
        mx: 1,
        my: 3,
        px: 3,
        py: 1,
        borderRadius: 7,
        bgcolor: "primary.main",
        color: "black",
      }}
      className="flex items-center justify-center flex-col"
    >
      <Typography align="center">{props.header}</Typography>
      <Typography align="center">{props.content}</Typography>
    </Box>
  );
}

function DaySelect(props) {
  return (
    <Box
      sx={
        props.isSelected
          ? {
              color: "white",
              borderRadius: 10,
              px: 2,
              py: 1,
              background: "#ffce00",
            }
          : {
              border: "1px solid gray",
              borderRadius: 10,
              px: 2,
              py: 1,
              background: "white",
            }
      }
      onClick={() => props.onClick(props.value)}
    >
      {props.value === 0 && <Typography>Custom</Typography>}
      {props.value !== 0 && <Typography>Last {props.value} days</Typography>}
    </Box>
  );
}

const Statistics = (props) => {
  React.useEffect(() => {
    props.getNetworkStats();
    props.getStatsByUser();
    props.getRewards();
    props.getTeamInfo();
    props.getTokenInfo();
  }, []);

  const { t } = useTranslation();

  const { network, stat } = props.stats;
  const { rewards } = props.staking;
  const { team } = props.teams;
  const { token } = props.settings;

  const getCnt = (members) => {
    if (members.length > 0) {
      for (let i = 0; i < members.length; i++) {
        totalCnt++;
        if (members[i].children.length > 0) getCnt(members[i].children);
      }
    }
  };

  let totalCnt = 0;
  if (team && team.members && team.members.children.length > 0) {
    getCnt(team.members.children);
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  // Your Stat
  let labelsForFive = [],
    labelsForTen = [],
    dataForFive = [],
    dataForTen = [];
  let labelsForFiveMembers = [],
    labelsForTenMembers = [],
    dataForFiveMembers = [],
    dataForTenMembers = [];
  if (stat && stat.totalFiveRewardsByDate.length > 1) {
    for (let item of stat.totalFiveRewardsByDate) {
      labelsForFive.push(
        new Intl.DateTimeFormat("tr-TR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(item.date).getTime())
      );
      dataForFive.push(item.total);
    }
  }

  if (stat && stat.totalTenRewardsByDate.length > 1) {
    for (let item of stat.totalTenRewardsByDate) {
      labelsForTen.push(
        new Intl.DateTimeFormat("tr-TR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(item.date).getTime())
      );
      dataForTen.push(item.total);
    }
  }

  if (stat && stat.totalMembersForFive.length > 1) {
    for (let item of stat.totalMembersForFive) {
      labelsForFiveMembers.push(
        new Intl.DateTimeFormat("tr-TR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(item.date).getTime())
      );
      dataForFiveMembers.push(item.total);
    }
  }

  if (stat && stat.totalMembersForTen.length > 1) {
    for (let item of stat.totalMembersForTen) {
      labelsForTenMembers.push(
        new Intl.DateTimeFormat("tr-TR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(item.date).getTime())
      );
      dataForTenMembers.push(item.total);
    }
  }

  const fiveInputForYourStat = {
    labelsForFive,
    datasets: [
      {
        label: "WHT",
        data: dataForFive,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const tenInputForYourStat = {
    labelsForTen,
    datasets: [
      {
        label: "WHT",
        data: dataForTen,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const fiveMembersInputForYourStat = {
    labelsForFiveMembers,
    datasets: [
      {
        label: "Members",
        data: dataForFiveMembers,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const tenMembersInputForYourStat = {
    labelsForTenMembers,
    datasets: [
      {
        label: "Members",
        data: dataForTenMembers,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  // Network Stat
  let labelsForFiveNetwork = [],
    labelsForTenNetwork = [],
    dataForFiveNetwork = [],
    dataForTenNetwork = [];
  let labelsForFiveMembersNetwork = [],
    labelsForTenMembersNetwork = [],
    dataForFiveMembersNetwork = [],
    dataForTenMembersNetwork = [];

  if (
    network &&
    network.totalFiveRewardsByDate &&
    network.totalFiveRewardsByDate.length > 1
  ) {
    for (let item of network.totalFiveRewardsByDate) {
      labelsForFiveNetwork.push(
        new Intl.DateTimeFormat("tr-TR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(item.date).getTime())
      );
      dataForFiveNetwork.push(item.total);
    }
  }

  if (
    network &&
    network.totalTenRewardsByDate &&
    network.totalTenRewardsByDate.length > 1
  ) {
    for (let item of network.totalTenRewardsByDate) {
      labelsForTenNetwork.push(
        new Intl.DateTimeFormat("tr-TR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(item.date).getTime())
      );
      dataForTenNetwork.push(item.total);
    }
  }

  if (
    network &&
    network.totalMembersForFive &&
    network.totalMembersForFive.length > 1
  ) {
    for (let item of network.totalMembersForFive) {
      labelsForFiveMembersNetwork.push(
        new Intl.DateTimeFormat("tr-TR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(item.date).getTime())
      );
      dataForFiveMembersNetwork.push(item.total);
    }
  }

  if (
    network &&
    network.totalMembersForTen &&
    network.totalMembersForTen.length > 1
  ) {
    for (let item of network.totalMembersForTen) {
      labelsForTenMembersNetwork.push(
        new Intl.DateTimeFormat("tr-TR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(item.date).getTime())
      );
      dataForTenMembersNetwork.push(item.total);
    }
  }

  const fiveInputForNetwork = {
    labelsForFiveNetwork,
    datasets: [
      {
        label: "WHT",
        data: dataForFiveNetwork,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const tenInputForNetwork = {
    labelsForTenNetwork,
    datasets: [
      {
        label: "WHT",
        data: dataForTenNetwork,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const fiveMembersInputForNetwork = {
    labelsForFiveMembersNetwork,
    datasets: [
      {
        label: "Members",
        data: dataForFiveMembersNetwork,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const tenMembersInputForNetwork = {
    labelsForTenMembersNetwork,
    datasets: [
      {
        label: "Members",
        data: dataForTenMembersNetwork,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const [value, setValue] = React.useState(0);
  const [lastDay, setLastDay] = React.useState(5);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab label={t("your_stats")} {...a11yProps(0)} />
          <Tab label={t("network_stats")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box sx={{ display: "grid", gridTemplateColumns: "auto auto" }}>
          <MyComponent header={totalCnt} content={t("my_team")} />
          <MyComponent
            header={stat && stat.staking ? Number(stat?.staking).toFixed(2) : 0}
            content={t("my_staking")}
          />
          <MyComponent
            header={
              stat && stat.totalRewards
                ? Number(stat?.totalRewards).toFixed(2)
                : 0
            }
            content={t("my_rewards")}
          />
          <MyComponent
            header={
              rewards &&
              Number(rewards.staking.rewards) &&
              Number(rewards.referral)
                ? Number(
                    Number(rewards.staking.rewards) + Number(rewards.referral)
                  ).toFixed(2)
                : 0
            }
            content={t("receivable_rewards")}
          />
          <MyComponent
            header={`${
              stat?.withdrawals ? Number(stat.withdrawals).toFixed(2) : 0
            } WHT`}
            content={t("my_withdrawals")}
          />
          <MyComponent
            header={`${
              rewards &&
              Number(rewards.staking.rewards) &&
              Number(rewards.referral)
                ? Number(
                    Number(rewards.staking.rewards) + Number(rewards.referral)
                  ).toFixed(2)
                : 0
            } WHT`}
            content={t("available_reward")}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>{t("your_rewards")}</Typography>
          <Typography>
            {lastDay === 5
              ? stat?.totalRewardsForFive
                ? stat.totalRewardsForFive
                : "0.0"
              : stat?.totalRewardsForTen
              ? stat.totalRewardsForTen
              : "0.0"}{" "}
            WHT
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 3,
          }}
        >
          <DaySelect
            isSelected={lastDay === 5 ? true : false}
            value={5}
            onClick={setLastDay}
          />
          <DaySelect
            isSelected={lastDay === 10 ? true : false}
            value={10}
            onClick={setLastDay}
          />
          <DaySelect
            isSelected={lastDay === 0 ? true : false}
            value={0}
            onClick={setLastDay}
          />
        </Box>
        <Box>
          {lastDay === 5 ? (
            <Line options={options} data={fiveInputForYourStat} />
          ) : (
            <Line options={options} data={tenInputForYourStat} />
          )}
        </Box>
        <Box sx={{ pb: "100px" }}>
          {lastDay === 5 ? (
            <Line options={options} data={fiveMembersInputForYourStat} />
          ) : (
            <Line options={options} data={tenMembersInputForYourStat} />
          )}
        </Box>
        <Box
          sx={{
            background: "white",
            borderRadius: 5,
            position: "fixed",
            bottom: "56px",
            height: "100px",
            width: {
              xs: "calc(min(100vw, 600px) - 32px)",
              sm: "calc(min(100vw, 600px) - 48px)",
            },
            display: "flex",
            justifyContent: "space-around",
          }}
          fullwidth="true"
        >
          <Button
            variant="contained"
            sx={{ mt: 2, mb: 2, minWidth: "150px", color: "black" }}
            className="button2"
            href="/withdraw"
          >
            {t("withdraw")} {t("rewards")}
          </Button>
          <Button
            variant="contained"
            sx={{ mt: 2, mb: 2, minWidth: "150px", color: "black" }}
            className="button1"
            href="/statement_details"
          >
            {t("statement_details")}
          </Button>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box sx={{ display: "grid", gridTemplateColumns: "auto auto" }}>
          <MyComponent
            header={network?.totalUsers}
            content="Total Participants"
          />
          <MyComponent
            header={`
             ${
               token && network && token.curPrice && network.totalStakedByUSD
                 ? Number(network.totalStakedByUSD).toFixed(3)
                 : 0
             }
             USDT`}
            content="Total Staking in Pool"
          />
          <MyComponent
            header={`${
              network && network.totalRewards
                ? Number(network?.totalRewards).toFixed(2)
                : 0.0
            } WHT (${
              token && network && token.curPrice && network.totalRewards
                ? (network.totalRewards * token.curPrice).toFixed(3)
                : 0
            } USDT)`}
            content="Total Rewards"
          />
          <MyComponent
            header={`${
              network && network.totalDeposits
                ? Number(network?.totalDeposits).toFixed(2)
                : 0.0
            } WHT (${
              token && network && token.curPrice && network.totalDepositsByUSD
                ? Number(network.totalDepositsByUSD).toFixed(3)
                : 0
            } USDT)`}
            content="Total Deposits"
          />
          <MyComponent
            header={`${
              network && network.totalWithdrawals
                ? Number(network?.totalWithdrawals).toFixed(2)
                : 0
            } WHT \n (${
              token &&
              network &&
              token.curPrice &&
              network.totalWithdrawalsByUSD
                ? Number(network.totalWithdrawalsByUSD).toFixed(3)
                : 0
            } USDT)`}
            content="Total Withdrawals"
          />
          <MyComponent
            header={`${
              network && network.totalStaked
                ? Number(network?.totalStaked).toFixed(2)
                : 0
            } WHT`}
            content="Total Staking in Pool"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography>Total Rewards</Typography>
            <Typography variant="body2">{lastDay} Days</Typography>
          </Box>
          <Typography>
            {lastDay === 5
              ? network?.totalRewardsForFive
                ? network.totalRewardsForFive
                : 0
              : network?.totalRewardsForTen
              ? network.totalRewardsForTen
              : 0}{" "}
            WHT
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
            my: 3,
          }}
        >
          <DaySelect
            isSelected={lastDay === 5}
            value={5}
            onClick={() => setLastDay(5)}
          />
          <DaySelect
            isSelected={lastDay === 10}
            value={10}
            onClick={() => setLastDay(10)}
          />
          <DaySelect
            isSelected={lastDay === 0}
            value={0}
            onClick={() => setLastDay(0)}
          />
        </Box>
        <Box>
          {lastDay === 5 ? (
            <Line options={options} data={fiveInputForNetwork} />
          ) : (
            <Line options={options} data={tenInputForNetwork} />
          )}
        </Box>
        <Box sx={{ pb: "100px" }}>
          {lastDay === 5 ? (
            <Line options={options} data={fiveMembersInputForNetwork} />
          ) : (
            <Line options={options} data={tenMembersInputForNetwork} />
          )}
        </Box>
        {/* <Box
          sx={{
            background: 'white',
            position: 'fixed',
            bottom: '56px',
            height: '100px',
            width: {
              xs: 'calc(min(100vw, 600px) - 32px)',
              sm: 'calc(min(100vw, 600px) - 48px)',
            },
            textAlign: 'center',
            paddingTop: '20px',
          }}
          fullwidth="true"
        >
          <Button variant="contained" sx={{ py: 2, borderRadius: 10 }}>
            <GroupIcon />
            Top Stakers & Earners
          </Button>
        </Box> */}
      </TabPanel>
    </Box>
  );
};

Statistics.propTypes = {
  getNetworkStats: PropTypes.func.isRequired,
  getStatsByUser: PropTypes.func.isRequired,
  getRewards: PropTypes.func.isRequired,
  getTeamInfo: PropTypes.func.isRequired,
  stats: PropTypes.object.isRequired,
  staking: PropTypes.object.isRequired,
  teams: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  stats: state.stats,
  settings: state.settings,
  staking: state.staking,
  teams: state.teams,
});

export default connect(mapStateToProps, {
  getNetworkStats,
  getStatsByUser,
  getRewards,
  getTeamInfo,
  getTokenInfo,
})(Statistics);
