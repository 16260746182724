import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import HomeIcon from '@mui/icons-material/Home'
import BarChartIcon from '@mui/icons-material/BarChart'
import PeopleIcon from '@mui/icons-material/People'
import FeedIcon from '@mui/icons-material/Feed'
import Paper from '@mui/material/Paper'

const Footer = ({ auth }) => {
  const { isAuthenticated } = auth

  const navigate = useNavigate()
  const [value, setValue] = React.useState(0)
  const ref = React.useRef(null)

  if (isAuthenticated)
    return (
      <Box sx={{ pb: 7, zIndex: 100000 }} ref={ref}>
        <CssBaseline />
        <Paper
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue)
              switch (newValue) {
                case 0:
                  navigate('/')
                  break
                case 1:
                  navigate('/statistics')
                  break
                case 2:
                  navigate('/team')
                  break
                case 3:
                  navigate('/news')
                  break
                default:
                  navigate('/')
                  break
              }
            }}
          >
            <BottomNavigationAction label="Home" icon={<HomeIcon />} />
            <BottomNavigationAction
              label="Statistics"
              icon={<BarChartIcon />}
            />
            <BottomNavigationAction label="Team" icon={<PeopleIcon />} />
            <BottomNavigationAction label="News" icon={<FeedIcon />} />
          </BottomNavigation>
        </Paper>
      </Box>
    )
  else {
    return (
      <Box sx={{ pb: 7 }} ref={ref}>
        <CssBaseline />
        <Paper
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <div className='p-4 text-center'><strong className='text-[#ffce00]'>Gold Bit</strong> | Crypto Staking &copy;2023</div>
        </Paper>
      </Box>
    )
  }
}

Footer.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, {})(Footer)
