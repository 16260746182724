import * as React from 'react'
import { Box, Typography } from '@mui/material'
import NoResultImage from '../../assets/images/no_result.png'
import { useTranslation } from 'react-i18next'

export default function Promotions() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img
        src={NoResultImage}
        className="w-[300px] m-auto"
        style={{ maxWidth: { md: '400px', xs: '100%' } }}
        alt="No Result"
      />
      <Typography variant="h4" color="text.third">
        {t('empty_title')}
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }} align="center">
        {t('empty_info')}{' '}{t('empty_info2')}
      </Typography>
    </Box>
  )
}
