import * as React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { withRouter } from '../../utils/withRouter'

import { SwalAlert } from '../../swal/SwalAlert'
import 'sweetalert2/src/sweetalert2.scss'

import {
  Typography,
  Box,
  InputLabel,
  Input,
  Button,
  InputAdornment,
} from '@mui/material'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import { useTranslation } from 'react-i18next'

import { getUserById } from '../../redux/actions/userActions'
import { withdraw } from '../../redux/actions/accountActions'

const Withdraw = ({ withdraw, users, router, getUserById, errors }) => {
  const { t } = useTranslation();
  const swalWithBootstrapButton = SwalAlert.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger',
    },
    buttonsStyling: true,
  })

  const { user } = users
  console.log(user);

  React.useEffect(() => {
    getUserById()
  }, [getUserById])

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    swalWithBootstrapButton
      .fire({
        title: 'Would you like to withdraw your WHT tokens now?',
        text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, Withdraw now!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (data.get('address') === '' || data.get('amount') === '')
            swalWithBootstrapButton.fire(
              'Warning!',
              'Please fill the form',
              'warning',
            )
          else {
            withdraw({ type: localStorage.getItem('wType') === 'usdt' ? 8 : 4, amount: data.get('amount') }, router.navigate, swalWithBootstrapButton)
          }
        } else if (result.dismiss === SwalAlert.DismissReason.cancel) {
          swalWithBootstrapButton.fire(
            'Cancelled',
            'Your balance is safe :)',
            'error',
          )
        }
      })
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
      <InputLabel htmlFor="address" sx={{ mt: 2 }}>
        {t('label_addr')}
      </InputLabel>
      <Input
        margin="dense"
        required
        fullWidth
        id="address"
        name="address"
        autoComplete="address"
        value={user?.walletBep}
        type="text"
        sx={{ mt: 1 }}
        endAdornment={
          <InputAdornment position="end">
            <ContentPasteIcon color="primary" />
          </InputAdornment>
        }
      />
      <InputLabel htmlFor="amount" sx={{ mt: 2 }}>
        {t('label_amt')}
      </InputLabel>
      <Input
        margin="dense"
        required
        fullWidth
        id="amount"
        type="number"
        name="amount"
        autoComplete="amount"
        sx={{ mt: 2 }}
        endAdornment={
          <InputAdornment position="end">
            <Typography>{localStorage.getItem('wType') && localStorage.getItem('wType').toUpperCase()}</Typography>
            {/* <Typography
              sx={{ ml: 1 }}
              color="text.third"
              className="cursor-pointer"
              onClick={handleMax}
            >
              Max
            </Typography> */}
          </InputAdornment>
        }
      />
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
        color="text.secondary"
      >
        <Typography>{t('available_withdrawal')}</Typography>
        <Typography>
          {user && localStorage.getItem('wType') && localStorage.getItem('wType') === 'usdt' ? Number(user.balance_gb).toFixed(2) : user && Number(user.income).toFixed(3)} {localStorage.getItem('wType') && localStorage.getItem('wType').toUpperCase()}
        </Typography>
      </Box>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 20, mb: 2, color: 'white' }}
        className="button1"
      >
        {t('btn_submit')}
      </Button>
    </Box>
  )
}

Withdraw.propTypes = {
  getUserById: PropTypes.func.isRequired,
  withdraw: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  users: state.users,
  errors: state.errors,
})

export default connect(mapStateToProps, { getUserById, withdraw })(
  withRouter(Withdraw),
)
