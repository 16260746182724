import * as React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { withRouter } from '../../utils/withRouter'

import {
  IconButton,
  Box,
  Typography,
  InputAdornment,
  Button,
  Input,
  InputLabel,
} from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { changePassword } from '../../redux/actions/userActions'
import { t } from 'i18next'

function Password(props) {
  const [showPassword, setShowPassword] = React.useState(true)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Box sx={{ mt: 2 }}>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <Input
        margin="dense"
        required
        fullWidth
        id={props.id}
        name={props.id}
        placeholder={props.placeholder}
        type={
          props.type !== 'password'
            ? props.type
            : showPassword
              ? 'password'
              : 'text'
        }
        autoComplete={props.id}
        startAdornment={
          <InputAdornment position="start">
            <LockIcon color="primary" />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        sx={{ mt: 1 }}
      />
    </Box>
  )
}

const ChangePassword = ({ changePassword, errors, router }) => {
  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    changePassword(
      {
        current: data.get('current'),
        password: data.get('password'),
        password2: data.get('password2'),
      },
      router.navigate,
    )
  }

  return (
    <>
      <Typography
        component="h1"
        variant="h5"
        align="center"
        color="text.secondary"
      >
        Change Your Password
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Password
          id="current"
          type="password"
          label={t('label_cp')}
          placeholder={t('ph_cp')}
        />
        {errors && errors.current && (
          <p className="text-red-500 text-[12px]">{errors.current}</p>
        )}
        <Password
          id="password"
          type="password"
          label={t('label_np')}
          placeholder={t('ph_password')}
        />
        {errors && errors.password && (
          <p className="text-red-500 text-[12px]">{errors.password}</p>
        )}
        <Password
          id="password2"
          type="password"
          label={t('label_rp')}
          placeholder={t('ph_cfPassword')}
        />
        {errors && errors.password2 && (
          <p className="text-red-500 text-[12px]">{errors.password2}</p>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 10, mb: 2 }}
          className="button1"
        >
          {t('btn_change')}
        </Button>
      </Box>
    </>
  )
}

ChangePassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  errors: state.errors,
})

export default connect(mapStateToProps, { changePassword })(
  withRouter(ChangePassword),
)
