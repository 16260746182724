import axios from "axios";
import { GET_ERRORS, GET_TEAM_INFO, GET_TEAM_TASK } from "../types";

export const getTeamInfo = () => (dispatch) => {
  axios
    // .get('http://localhost:5000/api/team')
    .get("https://api.gold-bit.io/api/team")
    .then((res) =>
      dispatch({
        type: GET_TEAM_INFO,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getTeamTasks = () => (dispatch) => {
  axios
    .get("https://api.gold-bit.io/api/team/tasks")
    .then((res) => {
      dispatch({
        type: GET_TEAM_TASK,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const applyTask = (id) => (dispatch) => {
  axios
    .get(`https://api.gold-bit.io/api/team/apply/${id}`)
    .then((res) => {
      getTeamInfo();
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
