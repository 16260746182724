import { GET_TEAM_INFO, GET_TEAM_TASK } from "../types";

const initialState = {
  team: null,
  task: null,
  loading: false,
};

export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEAM_INFO:
      return {
        ...state,
        team: action.payload,
        loading: false,
      };
    case GET_TEAM_TASK:
      return {
        ...state,
        task: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
