import * as React from 'react';
import { Typography, Button, Box } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QRImage from '../../assets/images/qr.png';

export default function Deposit() {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={QRImage} alt="QRImage" />
      </Box>
      <Typography align='center' sx={{ mt: 2 }} color="text.secondary">Please only deposit WLT token to this address</Typography>
      <Typography sx={{ mt: 2 }} color="text.secondary">Your Wallet Address</Typography>
      <Typography sx={{ mt: 2, display: 'flex' }} color="text.secondary">
        <AccountBalanceWalletIcon color="primary" sx={{ mr: 1 }} />
        0x5454545454545454545454545454adfaadfd
        <ShareIcon color="primary" sx={{ ml: 1 }} />
        <ContentCopyIcon color="primary" sx={{ ml: 1 }} />
      </Typography>
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, color: 'white' }}
        className="button1"
      >
        Submit
      </Button>
    </>
  );
}