import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withRouter } from "../../utils/withRouter";

import { SwalAlert } from "../../swal/SwalAlert";
import { Typography, Button, Box, Input } from "@mui/material";
import IconImage from "../../assets/images/icon.png";
import "sweetalert2/src/sweetalert2.scss";

import { getBalance, stakeOn, cancelStake } from "../../redux/actions/stakeActions";
import { getSettings } from "../../redux/actions/propertyActions";
import { getUserById } from "../../redux/actions/userActions";

import { useTranslation } from "react-i18next";

const swalWithBootstrapButton = SwalAlert.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: true,
});

const Staking = ({
  getBalance,
  getUserById,
  cancelStake,
  getSettings,
  stakeOn,
  staking,
  settings,
  users,
  router,
}) => {
  const { t } = useTranslation();
  const [amount, setAmount] = React.useState("");
  const [errors, setError] = React.useState(null);
  const { properties } = settings;
  const { balance } = staking;
  const { user } = users;

  React.useEffect(() => {
    getBalance();
    if (!properties) getSettings();
  }, [getBalance, properties, getSettings]);

  React.useEffect(() => {
    getUserById();
  }, [getUserById]);

  const handleCancelStake = (e) => {
    e.preventDefault();

    swalWithBootstrapButton
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, Cancel now!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          cancelStake(router.navigate, setError);
        } else if (result.dismiss === SwalAlert.DismissReason.cancel) {
          swalWithBootstrapButton.fire(
            "Cancelled",
            "Your balance is safe :)",
            "error"
          );
        }
      });
  };

  const handleStake = (e) => {
    e.preventDefault();

    swalWithBootstrapButton
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, Stake now!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (!properties)
            swalWithBootstrapButton.fire(
              "Error!",
              "Oops, Server Error Occured",
              "error"
            );
          else if (
            properties &&
            Number(properties.minimum_staking) > Number(amount)
          )
            swalWithBootstrapButton.fire(
              "Invalid Amount",
              `Staking amount must be greater than ${properties && Number(properties.minimum_staking)
              }`,
              "warning"
            );
          else if (balance && Number(amount) > Number(balance.balance))
            swalWithBootstrapButton.fire(
              "No Balance",
              "Your Balance is not enough",
              "warning"
            );
          else {
            stakeOn(
              { amount: amount, user_id: user.id },
              router.navigate,
              swalWithBootstrapButton,
              setError
            );
          }
        } else if (result.dismiss === SwalAlert.DismissReason.cancel) {
          swalWithBootstrapButton.fire(
            "Cancelled",
            "Your balance is safe :)",
            "error"
          );
        }
      });
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img src={IconImage} alt="IconImage" style={{ width: "60px" }} />
      </Box>
      <Typography
        align="center"
        sx={{ mt: 2 }}
        fontWeight="500"
        color="text.secondary"
        variant="h5"
      >
        {Number(amount).toFixed(1)}
      </Typography>
      <Input
        margin="dense"
        required
        fullWidth
        type={"number"}
        id="amount"
        name="amount"
        placeholder={t("ph_amt")}
        autoComplete="amount"
        sx={{ mt: 1 }}
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      {errors && errors.staking && <p className="error-msg text-red-500 text-base">{errors.staking}</p>}
      <Typography
        align="center"
        sx={{ mt: 2 }}
        color="text.secondary"
        variant="h5"
      >
        <strong>{balance && Number(balance.balance).toFixed(1)}</strong> WHT
      </Typography>
      <Typography
        align="center"
        sx={{ mt: 2 }}
        color="text.secondary"
        variant="h6"
      >
        {t("balance")}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, color: "black" }}
        className="button1"
        onClick={handleStake}
      >
        {t("stake_wht")}
      </Button>
      {/* <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, color: "black" }}
        className="button1"
        onClick={handleCancelStake}
      >
        Stop Staking
      </Button> */}
    </>
  );
};

Staking.propTypes = {
  getBalance: PropTypes.func.isRequired,
  getSettings: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  cancelStake: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  stakeOn: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  staking: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  staking: state.staking,
  settings: state.settings,
  users: state.users,
});

export default connect(mapStateToProps, {
  getUserById,
  getBalance,
  cancelStake,
  stakeOn,
  getSettings,
})(withRouter(Staking));
