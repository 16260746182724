import { Modal, Button, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 10,
  boxShadow: 24,
  p: 4,
};

export default function DepositModal({ open, handleClose }) {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('modal_dc')}
          </Typography>
          <CloseIcon onClick={handleClose} />
        </Box>
        <Button
          variant="contained"
          sx={{ mt: 3, mb: 2, color: 'white', px: 3 }}
          className="button1"
        >
          WHT
        </Button>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Deposit WHT tokens for staking.<br />
          The network fees for transaction on BSC Network.
        </Typography>
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, color: 'white' }}
          className="button1"
          href="/deposit"
        >
          Confirm
        </Button>
      </Box>
    </Modal>
  )
}