import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withRouter } from "../../utils/withRouter";

import { verifyToken } from "../../redux/actions/smsActions";

const Confirm = ({ verifyToken, router }) => {
  const { token } = useParams();

  useEffect(() => {
    verifyToken({ token: token }, router.navigate);
  }, [verifyToken, router, token]);

  return <div></div>;
};

Confirm.propTypes = {
  verifyToken: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { verifyToken })(withRouter(Confirm));
