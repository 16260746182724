import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  Input,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import gift01 from "../../assets/images/gift01.png";
import gift02 from "../../assets/images/gift02.png";
import gift03 from "../../assets/images/gift03.png";

import {
  getTeamInfo,
  getTeamTasks,
  applyTask,
} from "../../redux/actions/teamActions";
import { useTranslation } from "react-i18next";

function SocialFollow(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 5,
        background: "#ffce00",
        px: 1,
        py: 2,
        my: 2,
        mx: 0.5,
      }}
      onClick={() => props.setTab(props.tab)}
      className="cursor-pointer"
    >
      <Typography variant="body1" color="black" align="center" sx={{ mt: 1 }}>
        {props.followerCount}
      </Typography>
      <Typography variant="body1" color="black" align="center">
        {props.socialName}
      </Typography>
    </Box>
  );
}

function TaskCard(props) {
  const { type, title, description, target, apply, id, isApplied, progress } =
    props;

  return (
    <div className="flex flex-col sm:flex sm:flex-row items-center justify-between bg-white p-3 border-[1px] border-[#FFCE00] gap-3">
      <img
        src={type === "small" ? gift01 : type === "medium" ? gift02 : gift03}
        alt="small gift"
        className="w-14 h-14"
      />
      <div className="flex flex-col items-center justify-center gap-1">
        <p className="font-semibold">{title}</p>
        <p className="break-words line-clamp-3">{description}</p>
      </div>
      <button
        className="bg-[#ffce00] rounded-[20px] px-4 py-3"
        onClick={() => apply(id)}
        disabled={isApplied ? true : false}
      >
        {progress
          ? progress.isFinished
            ? "Finished"
            : !progress.isFinished && isApplied
            ? `Applied ${progress.amount} / ${target}`
            : "Apply"
          : "Apply"}
      </button>
    </div>
  );
}

function ChildCard(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 5,
        gap: 1,
      }}
      className="w-full break-words border-[1px] p-1 mt-1 bg-yellow-300"
    >
      <Typography
        variant="body1"
        color="black"
        align="center"
        className="w-full"
        sx={{ mt: 1 }}
      >
        <strong className="font-bold">{`${props.member.firstName} ${props.member.lastName}`}</strong>
      </Typography>
      <Typography
        variant="body1"
        color="black"
        align="center"
        className="w-full"
      >
        <strong className="font-bold">{props.member.email}</strong>
      </Typography>
      <Typography
        variant="body1"
        color="black"
        className="w-full"
        align="center"
      >
        Invited{" "}
        <strong className="font-bold">
          {props.member.refCnt ? props.member.refCnt : 0}
        </strong>{" "}
        members
      </Typography>
      <Typography
        variant="body1"
        color="black"
        className="w-full"
        align="center"
      >
        <strong className="font-bold">
          {props.member.active === 1 ? "Online" : "Offline"}
        </strong>
      </Typography>
    </Box>
  );
}

const IsAccordionOrNode = (props) => {
  const members = props.child;

  if (members.length > 0) {
    // Accordion
    return members.map((member, idx) => {
      member.refCnt = member.children.length;
      return (
        <Accordion key={idx} className="bg-yellow-400">
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls={member.code}
            id={member.code}
          >
            <ChildCard key={member.code} member={member} />
          </AccordionSummary>
          <AccordionDetails>
            {member.children.length > 0 ? (
              <IsAccordionOrNode key={member.code} child={member.children} />
            ) : (
              <Typography className="text-center">No members</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      );
    });
  }
};

const Team = ({ getTeamInfo, getTeamTasks, applyTask, teams }) => {
  const { t } = useTranslation();
  const { team, task } = teams;
  let tasks, appliedTasks;
  if (task) {
    tasks = task.tasks;
    appliedTasks = task.appliedTasks;
  }
  const [tab, setTab] = React.useState("home");
  const [keyword, setKeyword] = React.useState("");

  React.useEffect(() => {
    getTeamInfo();
    getTeamTasks();
  }, [getTeamInfo, getTeamTasks]);

  const getCnt = (members) => {
    if (members && members.length > 0) {
      for (let i = 0; i < members.length; i++) {
        totalCnt++;
        if (members[i].children.length > 0) getCnt(members[i].children);
      }
    }
  };

  const getCnt2 = (members) => {
    if (members && members.length > 0) {
      for (let i = 0; i < members.length; i++) {
        if (members[i].balance_gb !== 0 && members[i].wht_balance_gb !== 0)
          realCnt++;
        if (members[i].children.length > 0) getCnt2(members[i].children);
      }
    }
  };

  const getTotalStaked = (members) => {
    if (members && members.length > 0) {
      for (let i = 0; i < members.length; i++) {
        totalStaked += Number(members[i].staked);
        if (members[i].children.length > 0) getTotalStaked(members[i].children);
      }
    }
  };

  let totalCnt = 0,
    realCnt = 0,
    totalStaked = 0;
  if (team && team.members && team.members.children.length > 0) {
    getCnt(team.members.children);
    getCnt2(team.members.children);
  }

  const checkIfTaskIsApplied = (taskId, appliedTasks) => {
    if (appliedTasks && appliedTasks.length > 0) {
      for (let i = 0; i < appliedTasks.length; i++) {
        if (appliedTasks[i].m_id === taskId) return true;
      }
    }

    return false;
  };

  const checkIfTaskIsFinished = (type, target, totalCnt, members) => {
    let progress = {
      amount: 0,
      isFinished: false,
    };
    if (type === 0) {
      // Referral Members
      progress.amount = totalCnt;

      if (target <= totalCnt) progress.isFinished = true;
    } else {
      // Total Staked Amount
      if (!members) {
        progress.amount = 0;
        progress.isFinished = false;
      }

      getTotalStaked(members);
      progress.amount = totalStaked;
      if (target <= totalStaked) progress.isFinished = true;
    }

    totalStaked = 0;
    return progress;
  };

  let teamView;
  if (tab === "home") {
    teamView = (
      <Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
          <SocialFollow
            socialName={t("total_team")}
            tab="home"
            setTab={setTab}
            followerCount={
              team && team.totalUserCnt
                ? `+${Number(team.totalUserCnt) + 4299}`
                : 0
            }
          />
          <SocialFollow
            setTab={setTab}
            tab="team"
            socialName="Your Members"
            followerCount={totalCnt > 0 ? `+${totalCnt}` : 0}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 5,
            gap: 1,
          }}
        >
          <Typography>All Tasks</Typography>
          {tasks && tasks.length > 0 ? (
            <>
              {tasks.map((item, idx) => {
                const type =
                  item.type === 0
                    ? item.memCnt <= 100
                      ? "small"
                      : item.memCnt <= 200 && item.memCnt > 100
                      ? "medium"
                      : "large"
                    : item.amount < 100000
                    ? "small"
                    : item.amount >= 100000 && item.amount < 1000000
                    ? "medium"
                    : "large";

                const isApplied = checkIfTaskIsApplied(item.id, appliedTasks);
                let progress;
                if (isApplied)
                  progress = checkIfTaskIsFinished(
                    item.type,
                    item.type === 0 ? item.memCnt : item.amount,
                    realCnt,
                    team && team.members && team.members.children
                      ? team.members.children
                      : null
                  );

                return (
                  <TaskCard
                    key={idx}
                    type={type}
                    title={item.title}
                    description={item.description}
                    target={item.type === 0 ? item.memCnt : item.amount}
                    apply={applyTask}
                    id={item.id}
                    isApplied={isApplied}
                    progress={progress}
                  />
                );
              })}
            </>
          ) : (
            <Typography>No Tasks</Typography>
          )}
        </Box>
      </Box>
    );
  } else if (tab === "team" && team && team.members && team.memObj) {
    if (keyword && keyword !== "") {
      // Search Records
      const memObj = team.memObj;
      let filteredMemberObj = {};
      let fullFilledMemObj = [];

      // Filetr Members
      for (const key in memObj) {
        if (
          memObj[key].firstName?.toLowerCase().includes(keyword) ||
          memObj[key].lastName?.toLowerCase().includes(keyword) ||
          memObj[key].email?.toLowerCase().includes(keyword)
        )
          filteredMemberObj[key] = memObj[key];
      }

      // Retrieve Members by Child to Parent
      for (const key in filteredMemberObj) {
        let target = filteredMemberObj[key];
        if (target.code == "TSAT911WLV") continue;

        while (target && target.referralCode !== "TSAT911WLV")
          target = memObj[target.referralCode];

        if (!fullFilledMemObj.map((mem) => mem.code).includes(target.code))
          fullFilledMemObj.push(target);
      }

      teamView = (
        <>
          <div className="">
            <InputLabel htmlFor="subject" sx={{ mt: 2 }}>
              Enter your search keyword
            </InputLabel>
            <Input
              margin="dense"
              required
              fullWidth
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              id="subject"
              name="subject"
              placeholder="ex. name and email"
              type="text"
              autoComplete="subject"
              sx={{ mt: 1, mb: 1 }}
            />
          </div>
          <IsAccordionOrNode child={fullFilledMemObj} />
        </>
      );
    } else {
      teamView = (
        <>
          <div className="">
            <InputLabel htmlFor="subject" sx={{ mt: 2 }}>
              Enter your search keyword
            </InputLabel>
            <Input
              margin="dense"
              required
              fullWidth
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              id="subject"
              name="subject"
              placeholder="ex. name and email"
              type="text"
              autoComplete="subject"
              sx={{ mt: 1, mb: 1 }}
            />
          </div>
          <IsAccordionOrNode child={team.members.children} />
        </>
      );
    }
  }

  return (
    <div className="flex flex-col justify-between h-full">
      {teamView}
      {tab !== "home" && (
        <span
          onClick={() => setTab("home")}
          className="cursor-pointer font-bold mt-2 m-auto justify-center items-center flex"
        >
          Go Back
        </span>
      )}
    </div>
  );
};

Team.propTypes = {
  getTeamInfo: PropTypes.func.isRequired,
  getTeamTasks: PropTypes.func.isRequired,
  applyTask: PropTypes.func.isRequired,
  teams: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  teams: state.teams,
});

export default connect(mapStateToProps, {
  getTeamInfo,
  getTeamTasks,
  applyTask,
})(Team);
