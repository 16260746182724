import axios from 'axios'
import { GET_ERRORS, GET_FAQS } from '../types'

export const getFaqs = () => (dispatch) => {
  axios
    // .get('http://localhost:5000/api/faq')
    .get('https://api.gold-bit.io/api/faq')
    .then((res) =>
      dispatch({
        type: GET_FAQS,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }),
    )
}
