import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Box, Divider, Typography } from "@mui/material";

import { SwalAlert } from "../swal/SwalAlert";
import "sweetalert2/src/sweetalert2.scss";

import { getUserById } from "../redux/actions/userActions";
import { getBonus, claim } from "../redux/actions/stakeActions";

const RefBonus = ({ getUserById, getBonus, claim, users, staking }) => {
  const { referral } = staking;
  const { user } = users;

  useEffect(() => {
    getUserById()
  }, [getUserById]);

  useEffect(() => {
    if (user) getBonus(user?.id);
  }, [getBonus, user])

  const handleClaim = (e) => {
    e.preventDefault();

    const swalWithBootstrapButton = SwalAlert.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButton
      .fire({
        title: "Would you like to claim your reward now?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, Claim now!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (!referral || referral === 0)
            swalWithBootstrapButton.fire(
              "No Reward!",
              "You have no reward to claim",
              "warning"
            );
          else {
            claim(user?.id, getBonus);
            swalWithBootstrapButton.fire(
              "Congratulations!",
              "Your reward was claimed successfully!",
              "success"
            );
          }
        } else if (result.dismiss === SwalAlert.DismissReason.cancel) {
          swalWithBootstrapButton.fire(
            "Cancelled",
            "Your balance is safe :)",
            "error"
          );
        }
      });
  };

  return (
    <Box>
      <Typography sx={{ mt: 2 }} color="text.secondary">
        Referral Bonus
      </Typography>
      <Divider variant="middle" sx={{ marginTop: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 3,
          mt: 6,
        }}
      >
        <Box>
          <Typography
            align="center"
            display={"flex"}
            gap={2}
            fontSize={30}
            fontWeight={600}
            color={"#ffce00"}
          >
            You earned {referral ? referral : 0} coin from your references
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 10 }}>
        <Button
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            p: 4,
            color: "black",
            width: "100%",
            position: "relative",
            overflow: "hidden",
          }}
          className="button2"
          onClick={handleClaim}
        >
          <div className="w-full text-slate-500 absolute m-auto text-center flex items-center justify-center">
            <span className="text-lg text-center">Claim Bonus</span>
          </div>
        </Button>
      </Box>
    </Box>
  );
};

RefBonus.propTypes = {
  auth: PropTypes.object.isRequired,
  staking: PropTypes.object.isRequired,
  getBonus: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  claim: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  staking: state.staking,
  settings: state.settings,
  users: state.users,
});

export default connect(mapStateToProps, {
  getUserById,
  getBonus,
  claim,
})(RefBonus);
