import { SET_CURRENT_USER, GET_CODES } from "../types";

import isEmpty from "../../utils/isEmpty";

const initialState = {
  token: null,
  isAuthenticated: false,
  loading: true,
  codes: null,
  user: {},
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        token: action.payload.token,
        isAuthenticated: !isEmpty(action.payload.decoded),
        loading: false,
        user: action.payload.decoded,
      };
    case GET_CODES:
      return {
        ...state,
        codes: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
