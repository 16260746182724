import axios from "axios";
import { GET_ERRORS } from "../types";

export const sendMail = (userData, navigate) => (dispatch) => {
  axios
    // .post("http://localhost:5000/api/sms", userData)
    .post("https://api.gold-bit.io/api/sms", userData)
    .then((res) => navigate("/"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const verifyToken = (token, navigate) => (dispatch) => {
  axios
    // .post("http://localhost:5000/api/sms/verify", token)
    .post("https://api.gold-bit.io/api/sms/verify", token)
    .then((res) => {
      const { token, success } = res.data;
      if (success) navigate(`/reset-password/${token}`);
      else navigate("/");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const resetPassword = (passData, navigate) => (dispatch) => {
  axios
    // .post("http://localhost:5000/api/sms/reset", passData)
    .post("https://api.gold-bit.io/api/sms/reset", passData)
    .then((res) => navigate("/"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
