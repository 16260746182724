import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { SwalAlert } from "../../swal/SwalAlert";
import "sweetalert2/src/sweetalert2.scss";

import { Button, Box, Divider, Typography } from "@mui/material";

import { getUserById } from "../../redux/actions/userActions";
import { getGiftBonus } from "../../redux/actions/stakeActions";
import { getGiftInfo } from "../../redux/actions/propertyActions";
import { startAndClaimBonus } from "../../redux/actions/stakeActions";
import { useTranslation } from "react-i18next";
import ProgressCountdown from "../../components/ProgressCountdown";
import { useCountUp } from "react-countup";

const swalWithBootstrapButton = SwalAlert.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: true,
});

const MyBonus = ({
  getUserById,
  getGiftBonus,
  getGiftInfo,
  startAndClaimBonus,
  users,
  settings,
}) => {
  const { t } = useTranslation();
  const { user } = users;
  const { gift } = settings;
  const [isClaimable, setClaimable] = React.useState(false);
  const [diff, setDiff] = React.useState(0);
  const [diffSec, setDiffSec] = React.useState(0);
  const [currentTime, setCurrentTime] = React.useState(new Date());
  const [deadline, setDeadline] = React.useState(new Date());
  const [giftAmount, setGiftAmount] = React.useState("");
  const [end, setEnd] = React.useState(0);
  const [progressBar, setProgressBar] = React.useState(0);
  const [updateTrigger, setUpdateTrigger] = React.useState(0);

  const countUpRef = React.useRef(null);

  useCountUp({
    ref: countUpRef,
    start: user
      ? Number(
          (
            Math.abs(24 - (diff > 0 ? diff : 0)) *
            (user.bonus / 30 / 24)
          ).toFixed(3)
        )
      : 0.0,
    end: user ? Number((user.bonus / 30).toFixed(3)) : 0.0,
    duration: diffSec,
    decimals: 3,
  });

  const giftCountUpRef = React.useRef(null);
  const { start } = useCountUp({
    ref: giftCountUpRef,
    start: 0,
    end: end,
    duration: 0.5,
    decimals: 3,
  });

  React.useEffect(() => {
    getUserById();
    getGiftInfo();
  }, []);

  React.useEffect(() => {
    if (gift) {
      setEnd(gift.amount);
      setGiftAmount(String(gift.amount));
    }
  }, [gift]);

  React.useEffect(() => {
    getUserById();
  }, [updateTrigger]);

  React.useEffect(() => {
    const currentTime = new Date(
      new Date(new Date().getTime() + 2 * 3600 * 1000)
        .toUTCString()
        .replace(/ GMT$/, "")
    );
    const startTime = new Date(
      new Date(new Date(user?.claimed_at).getTime() + 3 * 3600 * 1000)
        .toUTCString()
        .replace(/ GMT$/, "")
    );
    const deadline = new Date(startTime.setDate(startTime.getDate() + 1));
    if (user?.isClaiming !== 0) {
      if (deadline.getTime() - currentTime.getTime() < 0) {
        setClaimable(true);
      }
      const diff_ = (deadline.getTime() - currentTime.getTime()) / 3600 / 1000;
      setDiff(diff_);
      const diffSec_ = (deadline.getTime() - currentTime.getTime()) / 1000;
      setDiffSec(diffSec_);
    }
    setCurrentTime(currentTime);
    setDeadline(deadline);
  }, [user]);

  React.useEffect(() => {
    if (diffSec !== 0) {
      let temp = 0;
      if ((100 / 86400) * (86400 - diffSec) > 100) temp = 100;
      else temp = (100 / 86400) * (86400 - diffSec);
      setProgressBar(temp);
    }
  }, [diffSec, user]);

  let intervalId;
  React.useEffect(() => {
    if (diffSec !== NaN && diffSec !== 0) {
      intervalId = setInterval(() => {
        setProgressBar(
          progressBar + 100 / 86400 > 100 ? 0 : progressBar + 100 / 86400
        );
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [user]);

  React.useEffect(() => {
    if (user?.isClaiming !== 0) {
      const currentTime = new Date();
      const deadline = new Date(
        new Date(user?.claimed_at).getTime() + 24 * 3600 * 1000
      );
      if (deadline.getTime() - currentTime.getTime() < 0) {
        setClaimable(true);
      } else {
        setClaimable(false);
      }
    } else {
      setClaimable(false);
    }
  }, [user?.isClaiming]);

  const handleClaim = () => {
    const type = user && user.isClaiming === 0 ? true : false;

    swalWithBootstrapButton
      .fire({
        title: `Would you like to ${
          type ? "start claiming" : "claim"
        } your bonus?`,
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: `Yes, ${type ? "Start" : "Claim"} now!`,
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          if (isClaimable) {
            const isSuccess = await startAndClaimBonus(type);
            if (isSuccess) {
              swalWithBootstrapButton.fire(
                "Congratulations!",
                "Your reward was claimed successfully!",
                "success"
              );
              window.location.reload();
            } else {
              swalWithBootstrapButton.fire(
                "Something went wrong!",
                isSuccess,
                "warning"
              );
            }
          } else {
            if (user && user.isClaiming === 0) {
              const isSuccess = await startAndClaimBonus(type);
              if (isSuccess) {
                swalWithBootstrapButton.fire(
                  "Congratulations!",
                  "You started claiming successfully!",
                  "success"
                );
                window.location.reload();
              } else
                swalWithBootstrapButton.fire(
                  "Something went wrong!",
                  isSuccess,
                  "warning"
                );
            } else {
              swalWithBootstrapButton.fire(
                "Warning!",
                "Claiming is not completed yet",
                "warning"
              );
            }
          }
        } else if (result.dismiss === SwalAlert.DismissReason.cancel) {
          swalWithBootstrapButton.fire(
            "Cancelled",
            "Your balance is safe :)",
            "error"
          );
        }
      });
  };

  const handleClaimGift = () => {
    start();
    setGiftAmount("0.000");
    getGiftBonus();
    swalWithBootstrapButton.fire(
      "Congratulations!",
      "You claimed your registration gift successfully!",
      "success"
    );
  };

  return (
    <Box>
      <Typography sx={{ mt: 2 }} color="text.secondary">
        {t("menu2_bonus")} {user?.totalBonus} WHT
      </Typography>
      <Divider variant="middle" sx={{ marginTop: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 3,
          mt: 6,
        }}
      >
        {user && user.giftStatus === 0 ? (
          <>
            <Box>
              <Typography align="center" fontSize={20}>
                {user?.firstName + " " + user?.lastName}
              </Typography>
            </Box>
            <Box>
              <Typography
                align="center"
                display={"flex"}
                gap={2}
                fontSize={30}
                fontWeight={600}
                color={"#ffce00"}
              >
                Locked Bonus WHT <div ref={giftCountUpRef} />
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Typography align="center" fontSize={20}>
                {user?.firstName + " " + user?.lastName}
              </Typography>
            </Box>
            <Box>
              <Typography
                align="center"
                fontSize={30}
                fontWeight={600}
                color={"#ffce00"}
              >
                Locked Bonus WHT {user && Number(user.bonus).toFixed(2)}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ mt: 10 }}>
        <Button
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            p: 4,
            color: "black",
            width: "100%",
            position: "relative",
            overflow: "hidden",
          }}
          className="button2"
          onClick={
            user && user.giftStatus === 0 ? handleClaimGift : handleClaim
          }
        >
          {user && user.giftStatus === 0 ? (
            <>
              <div className="w-full text-slate-500 absolute flex items-center justify-between">
                <div className="w-20"></div>
                <div className="flex gap-2 items-center justify-center">
                  <span className="text-lg">Claim Gift</span>
                  <div className="text-2xl">{giftAmount}</div>
                </div>
                <div className="w-20 pr-3">00:00:00</div>
              </div>
            </>
          ) : isClaimable ? (
            <>
              <div
                style={{ width: 100 + "%" }}
                className={`absolute h-full bg-[#ffce00] left-0 rounded-[10em]`}
              ></div>
              <div className="w-full text-slate-500 absolute flex items-center justify-between">
                <div className="w-20"></div>
                <div className="flex gap-2 items-center justify-center">
                  <span className="text-lg">Claim Bonus</span>
                  <div className="text-2xl">
                    {Number((user.bonus / 30).toFixed(3))}
                  </div>
                </div>
                <div className="w-20 pr-3">00:00:00</div>
              </div>
            </>
          ) : user && user.isClaiming === 0 ? (
            <>
              <div
                style={{ width: 0 + "%" }}
                className={`absolute h-full bg-[#ffce00] left-0 rounded-l-[10em]`}
              ></div>
              <div className="w-full text-slate-500 absolute flex items-center justify-between">
                <div className="w-20"></div>
                <div className="flex gap-2 items-center justify-center">
                  <span className="text-lg">Start Claiming</span>
                  <div className="text-2xl">0.000</div>
                </div>
                <div className="w-20 pr-3">24:00:00</div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{ width: progressBar + "%" }}
                className={`absolute h-full bg-[#ffce00] left-0 rounded-l-[10em]`}
              ></div>
              <div className="w-full text-slate-500 absolute flex items-center justify-between">
                <div className="w-20"></div>
                <div className="flex gap-2 items-center justify-center">
                  <span className="text-lg">Farming...</span>
                  <div className="text-2xl" ref={countUpRef} />
                </div>
                <div className="w-20 pr-3">
                  <ProgressCountdown
                    base={currentTime}
                    hideBar={true}
                    deadline={deadline}
                  />
                </div>
              </div>
            </>
          )}
        </Button>
      </Box>
    </Box>
  );
};

MyBonus.propTypes = {
  getUserById: PropTypes.func.isRequired,
  getGiftBonus: PropTypes.func.isRequired,
  getGiftInfo: PropTypes.func.isRequired,
  startAndClaimBonus: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  users: state.users,
});

export default connect(mapStateToProps, {
  getUserById,
  getGiftBonus,
  startAndClaimBonus,
  getGiftInfo,
})(MyBonus);
