import axios from "axios";

import {
  GET_BALANCE,
  GET_ERRORS,
  GET_STAKING_REWARDS,
  GET_REFERRAL_BONUS,
} from "../types";
import { getUserById } from "./userActions";

export const getRewards = () => (dispatch) => {
  axios
    // .get('http://localhost:5000/api/stake')
    .get("https://api.gold-bit.io/api/stake")
    .then((res) => {
      dispatch({
        type: GET_STAKING_REWARDS,
        payload: res.data.staking,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getBonus = (id) => (dispatch) => {
  console.log("Get Bonus");
  axios
    .post("https://admin.gold-bit.io/api/storage", { user_id: id })
    .then((res) => {
      dispatch({
        type: GET_REFERRAL_BONUS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getBalance = () => (dispatch) => {
  axios
    // .get('http://localhost:5000/api/stake/balance')
    .get("https://api.gold-bit.io/api/stake/balance")
    .then((res) => {
      dispatch({
        type: GET_BALANCE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const cancelStake = (navigate, setError) => (dispatch) => {
  axios
    // .post('http://localhost:5000/api/stake', stakeData)
    .get("https://api.gold-bit.io/api/stake/cancel")
    .then((res) => navigate("/home"))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      setError(err.response.data)
    }
    );
};

export const stakeOn =
  (stakeData, navigate, swalWithBootstrapButton, setError) => (dispatch) => {
    axios
      .post("https://admin.gold-bit.io/api/referralStake", stakeData)
      .then((res) => {
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        swalWithBootstrapButton.fire(
          "Failed!",
          "You already started staking! Otherwise, please try again.",
          "error"
        );
      });

    axios
      // .post('http://localhost:5000/api/stake', stakeData)
      .post("https://api.gold-bit.io/api/stake", stakeData)
      .then((res) => navigate("/home"))
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
        setError(err.response.data);
      }
      );
  };

export const compound = (claimData, navigate, getRewards) => (dispatch) => {
  axios
    // .post('http://localhost:5000/api/stake/compound', claimData)
    .post("https://api.gold-bit.io/api/stake/compound", claimData)
    .then((res) => {
      getRewards();
      navigate("/");
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const claim = (id, getBonus) => (dispatch) => {
  axios
    .post("https://admin.gold-bit.io/api/claim", { user_id: id })
    .then((res) => {
      getBonus(id);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: "Internal Server Error",
      })
    );
};

export const startAndClaimBonus = (type) => async (dispatch) => {
  const resp = await axios.post("https://api.gold-bit.io/api/stake/claim", {
    type,
  });

  if (resp.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const getGiftBonus = () => async (dispatch) => {
  const resp = await axios.get("https://api.gold-bit.io/api/stake/gift");

  if (resp.status === 200) {
    getUserById();
    return true;
  } else {
    return false;
  }
};
