export default {
  login_title: 'Giriş Yap',
  login_info: 'Haydi WHT Kazanmaya Başlayın',
  label_email: 'E-posta Adresi',
  ph_email: 'E-posta adresinizi giriniz',
  label_password: 'Şifre',
  ph_password: 'Şifrenizi girin',
  remember_me: 'Beni Hatırla',
  forgot_password: 'Şifreyi unut?',
  have_account: 'Bir hesabınız yok mu?',
  register_title: 'Üye olmak',
  label_fName: 'İlk adı',
  ph_fName: 'Adınızı girin',
  label_lName: 'Soyadı',
  ph_lName: 'Soyadınızı giriniz',
  label_username: 'Kullanıcı adı',
  ph_username: 'Kullanıcı adınızı giriniz',
  label_cfPassword: 'Şifreyi Onayla',
  ph_cfPassword: 'Şifrenizi yeniden yazın',
  label_refCode: 'Referans Kodu',
  ph_refCode: 'Referans Kodunuzu girin',
  agree_term:
    'Kaydolmak için buna tıklayarak, Şartlar ve Koşullarımızı kabul etmiş olursunuz.',
  already_account: 'Zaten hesabınız var mı?',
  privateKey_info:
    'İşte özel anahtarınız. Lütfen bu anahtarı kaydedin ve paylaşmayın. Hesabınızda oturum açmak için kullanılır.',
  balance: 'Denge',
  price: 'Fiyat',
  add_referral: 'Referans Ekle',
  stake_wht: 'Stake WHT',
  tap: 'Ödülünü almak için dokun',
  days: 'günler',
  rewards: 'Ödüller',
  staking: 'Staking',
  referral: 'Referans',
  withdraw: 'Para Çek',
  deposit: 'Depozito',
  menu_home: 'Anasayfa',
  menu_statistics: 'İstatistik',
  menu_team: 'Ekip',
  menu_news: 'Haberler',
  news_title: 'Haberler, Güncellemeler',
  empty_title: 'Bu boş',
  empty_info: 'Hmm.. sen bilmiyorsun gibi görünüyor',
  empty_info2: 'herhangi bir beyanı var',
  notification_title: 'Bildirimler',
  menu2_profile: 'Benim profilim',
  menu2_bonus: 'Bonusum',
  you_earned: 'Kazandın',
  menu2_cp: 'Şifre değiştir',
  menu2_wallet: 'Cüzdan',
  menu2_privateKey: 'Özel anahtar',
  menu2_about: 'Hakkında',
  menu2_promotions: 'Promosyonlar',
  menu2_faq: 'Sıkça Sorulan Sorular',
  menu2_support: 'Destek',
  menu2_pdf: "CyberGold Ağı PDF'si",
  menu2_connect: 'Bizimle iletişime geçin',
  menu2_logout: 'Çıkış Yap',
  profile_title: 'Profil',
  profile_details: 'profil detayları',
  your_name: 'Adınız',
  cp_caption: 'Şifreni değiştir',
  label_cp: 'Şimdiki Şifre',
  ph_cp: 'Mevcut şifrenizi girin',
  btn_change: 'Değiştirmek',
  label_np: 'Yeni Şifre',
  label_rp: 'Yeni Şifreyi Tekrar Yazın',
  your_wallet: 'Cüzdanın',
  label_wallet: 'Cüzdan Adresiniz',
  modal_dc: 'Yatırılacak Mevcut Varlık',
  modal_di: 'Staking için WHT jetonlarını yatırın.',
  modal_di2: "Lütfen cyber-bit.io'ya gidin ve WHT jetonu satın alın",
  modal_wc: "Çekilecek Varlık'ı seçin",
  modal_wi: 'BSC Ağı üzerindeki işlemler için ağ ücretleri',
  btn_confirm: 'Onaylamak',
  label_addr: 'Adres',
  label_amt: 'Miktar',
  ph_addr: 'Cüzdan Adresinizi girin',
  ph_amt: 'Tutarınızı girin',
  available_withdrawal: 'Para Çekmeye Uygun',
  about_title: 'Hakkımızda',
  gb_network: 'CyberGold Ağı',
  gb_token: 'CyberGold Token',
  gb_info:
    'CyberGold Network, akıllı telefon ve bilgisayar kullanıcıları için geliştirilmiş bir ödül dağıtım platformudur. Platform, kullanıcıların WHT Jetonlarını kilitlemesine ve günlük ödüller ve ekip ödülleri kazanmasına olanak tanıyan sözleşmeler kullanır.',
  gb_info2:
    "WHT belirteci bir dijital varlık / topluluk belirtecidir. WHT jetonunun ilk arzı 20 Milyar GB jetondur. Tedarik dağıtımı, 3 aşamalı bir ITO (İlk Belirteç Arzı) aracılığıyla başlayacaktır. Toplam ilk arz, her ay arzın %10'unu yakmak üzere tasarlanmış bir yakma mekanizması ile 3 Trilyon WHT jetonu olacak.",
  label_support: 'Nasıl yardımcı olabiliriz?',
  ph_subject: 'ders',
  label_description: 'Tanım',
  ph_description:
    'Merhaba, size nasıl yardımcı olabileceğimizi bize bildirin...',
  btn_submit: 'Göndermek',
  connect_title: 'Bizimle iletişime geçin',
  total_team: 'Toplam Ekip Üyesi',
  active_team: 'Aktif Ekip üyeleri',
  inactive_team: 'Etkin Olmayan Ekip Üyeleri',
  direct_members: 'Doğrudan Üyeler',
  team_level: 'Takım Seviyesi',
  team_levels: 'Takım Seviyeleri',
  lable_level: 'Seviye',
  your_stats: 'İSTATİSTİKLERİNİZ',
  network_stats: 'AĞ İSTATİSTİKLERİ',
  my_team: 'Benim takımım',
  my_staking: 'Benim Stakingim',
  my_rewards: 'Ödüllerim',
  receivable_rewards: 'Alacak Ödüller',
  my_withdrawals: 'Para Çekme İşlemlerim',
  available_reward: 'Mevcut Ödül',
  statement_details: 'Ekstre Ayrıntıları',
  your_rewards: 'Ödülleriniz',
  last_5days: 'Son 5 gün',
  last_10days: 'Son 10 gün',
  custom: 'Custom',
  total_participants: 'Toplam Katılımcı',
  total_staked: 'Havuzda Toplam Hisse',
  total_rewards: 'Toplam ödüller',
  total_withdrawals: 'Toplam Para Çekme',
  top_stakers: 'En Çok Stakers ve Kazananlar',
  pk_caption: 'Güvenli ve gizli bir yere kaydedin',
  pk_info:
    "Uyarı: Bu özel anahtarı asla ifşa etmeyin. Özel anahtarınıza sahip olan herkes, WHT'nizi başkasına aktarmak da dahil olmak üzere cüzdanınızı tam olarak kontrol edebilir.",
  copy_pk: 'Özel Anahtarı Kopyala',
};
