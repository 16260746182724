import { GET_BALANCE, GET_REFERRAL_BONUS, GET_STAKING_REWARDS } from "../types";

const initialState = {
  staking_: null,
  referral: null,
  balance: null,
  loading: false,
};

export default function stakeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STAKING_REWARDS:
      return {
        ...state,
        staking_: action.payload,
        loading: false,
      };
    case GET_REFERRAL_BONUS:
      return {
        ...state,
        referral: action.payload,
        loading: false,
      };
    case GET_BALANCE:
      return {
        ...state,
        balance: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
