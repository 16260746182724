import React, { useState } from "react";
import { Modal, Button, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 10,
  boxShadow: 24,
  p: 4,
};

export default function WithdrawModal({ open, handleClose }) {
  const { t } = useTranslation();
  const [type_, setType] = useState(true);

  const handleSwitchTokenType = (type) => {
    localStorage.setItem("wType", type);
    setType(!type_);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("modal_wc")}
          </Typography>
          <CloseIcon onClick={handleClose} />
        </Box>
        {type_ ? (
          <>
            <Button
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "black",
                color: "white",
                paddingTop: "16px",
                paddingBottom: "16px",
                borderRadius: "40px",
                px: 3,
              }}
              onClick={() => handleSwitchTokenType("wht")}
            >
              WHT
            </Button>
            &nbsp;
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2, color: "white", px: 3 }}
              className="button1"
              onClick={() => handleSwitchTokenType("usdt")}
            >
              USDT
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2, color: "white", px: 3 }}
              className="button1"
              onClick={() => handleSwitchTokenType("wht")}
            >
              WHT
            </Button>
            &nbsp;
            <Button
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "black",
                color: "white",
                paddingTop: "16px",
                paddingBottom: "16px",
                borderRadius: "40px",
                px: 3,
              }}
              onClick={() => handleSwitchTokenType("usdt")}
            >
              USDT
            </Button>
          </>
        )}

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t("modal_wi")}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, color: "white" }}
          className="button1"
          href="/withdraw"
        >
          {t("btn_confirm")}
        </Button>
      </Box>
    </Modal>
  );
}
