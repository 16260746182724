import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Box,
  Typography,
  Input,
  InputLabel,
  InputAdornment,
  Button,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

import { withRouter } from "../../utils/withRouter";

import { sendMail } from "../../redux/actions/smsActions";

const SendPassToMail = ({ sendMail, router }) => {
  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const userData = {
      email: data.get("email"),
    };

    sendMail(userData, router.navigate);
  };

  return (
    <Box>
      <Typography
        component="h1"
        variant="h5"
        align="center"
        color="text.secondary"
      >
        Send Mail
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <InputLabel htmlFor="email">Email Address</InputLabel>
        <Input
          margin="dense"
          required
          fullWidth
          id="email"
          name="email"
          placeholder="Enter your Email Address"
          autoComplete="email"
          startAdornment={
            <InputAdornment position="start">
              <PersonIcon color="primary" />
            </InputAdornment>
          }
          sx={{ mt: 1 }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          className="button1"
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

SendPassToMail.propTypes = {
  sendMail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { sendMail })(
  withRouter(SendPassToMail)
);
