import * as React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Avatar,
  Button,
  Tooltip,
  Container,
  MenuItem,
  Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoImage from "../assets/images/logo.png";
import { useTranslation } from "react-i18next";

import { logOutUser } from "../redux/actions/authActions";
import whitepaper from "../assets/gb_whitepaper.pdf";
// import LanguageSwitch from './LanguageSwitch'

const Header = ({ logOutUser, auth }) => {
  const { t } = useTranslation();

  const pages = [
    {
      title: t("menu2_about"),
      link: "/about_us",
    },
    {
      title: t("menu2_promotions"),
      link: "/promotions",
    },
    {
      title: t("menu2_faq"),
      link: "/faqs",
    },
    // {
    //   title: "Whitepaper",
    //   link: "/whitepaper",
    // },
    {
      title: t("menu2_connect"),
      link: "/connect_with_us",
    },
  ];
  const settings = [
    {
      title: t("menu2_profile"),
      link: "/profile",
    },
    {
      title: t("menu2_cp"),
      link: "/change_password",
    },
    {
      title: t("menu2_wallet"),
      link: "/wallet",
    },
    {
      title: t("menu2_bonus"),
      link: "/my-bonus",
    },
    {
      title: "Referral Bonus",
      link: "/ref-bonus",
    },
    {
      title: t("menu2_privateKey"),
      link: "/private_key",
    },
    {
      title: t("menu2_logout"),
      link: "/logout",
    },
  ];

  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { isAuthenticated } = auth;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (link = null) => {
    setAnchorElNav(null);
    if (link && link === "/whitepaper") {
      window.open(
        "https://gold-bit.io/static/media/gb_whitepaper.333faa4670d1b7d247f0.pdf",
        "_blank",
        "noreferrer"
      );
      return;
    }
    if (link) navigate(link);
  };

  const handleCloseUserMenu = (link = null) => {
    setAnchorElUser(null);
    if (link && link === "/logout") logOutUser();
    else if (link) navigate(link);
  };

  let guestLinks, authLinks;
  if (isAuthenticated) {
    authLinks = (
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt="U" src="/static/images/avatar/2.jpg" />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={() => handleCloseUserMenu()}
        >
          {settings.map((setting) => (
            <MenuItem
              key={setting.title}
              onClick={() => handleCloseUserMenu(setting.link)}
            >
              <Typography textAlign="center">{setting.title}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  } else {
    guestLinks = (
      <Box
        className="flex flex-col md:flex-row items-center justify-end w-full"
        sx={{ flexGrow: 0 }}
      >
        <MenuItem onClick={() => handleCloseUserMenu("/signin")}>
          <Typography className="text-[#ffce00]" textAlign="center">
            {t("login_title")}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleCloseUserMenu("/signup")}>
          <Typography className="text-[#ffce00]" textAlign="center">
            {t("register_title")}
          </Typography>
        </MenuItem>
      </Box>
    );
  }

  return (
    <AppBar position="static" color="secondary">
      <Box className="m-auto top-0 left-0 w-full justify-center items-center flex fixed z-30 bg-[#444444]">
        <Toolbar disableGutters>
          <Link href="/">
            <img
              src={LogoImage}
              style={{ marginRight: ".5em", width: "200px" }}
              className="md-flex xs-none"
              alt=""
            />
          </Link>

          {isAuthenticated && (
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={() => handleCloseNavMenu()}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => {
                  if (page.link && page.link === "/whitepaper") {
                    return (
                      <a
                        href={whitepaper}
                        className="MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-2yhey1-MuiButtonBase-root-MuiMenuItem-root"
                        target="_blank"
                      >
                        Read Whitepaper
                      </a>
                    );
                  } else {
                    return (
                      <MenuItem
                        key={page.title}
                        onClick={() => handleCloseNavMenu(page.link)}
                      >
                        <Typography textAlign="center">{page.title}</Typography>
                      </MenuItem>
                    );
                  }
                })}
              </Menu>
            </Box>
          )}

          <Link href="/">
            <img
              src={LogoImage}
              style={{ marginRight: ".2em", width: "150px" }}
              className="xs-flex md-none"
              alt=""
            />
          </Link>

          {isAuthenticated && (
            <>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {pages.map((page) => {
                  if (page.link && page.link === "/whitepaper") {
                    return (
                      <a
                        href={whitepaper}
                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary css-mz41d9-MuiButtonBase-root-MuiButton-root"
                        target="_blank"
                      >
                        Read Whitepaper
                      </a>
                    );
                  } else {
                    return (
                      <Button
                        key={page.title}
                        onClick={() => handleCloseNavMenu(page.link)}
                        sx={{ my: 2, color: "text.third", display: "block" }}
                      >
                        {page.title}
                      </Button>
                    );
                  }
                })}
              </Box>
            </>
          )}

          {isAuthenticated && (
            <div className="flex flex-col md:flex-row items-center">
              {/* <LanguageSwitch /> */}
              <Link href="/notification">
                <NotificationsIcon color="primary" sx={{ mr: 3 }} />
              </Link>
            </div>
          )}

          {isAuthenticated ? authLinks : guestLinks}
        </Toolbar>
      </Box>
    </AppBar>
  );
};

Header.propTypes = {
  logOutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logOutUser })(Header);
