import * as React from 'react';
import { Box, Typography } from '@mui/material';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconImage from "../../assets/images/icon.png";

function Level(props) {
  return (
    <Box sx={{ background: 'white', borderRadius: 10, my: 5, px: 5, py: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} color="text.secondary">
      <Box>
        <Typography variant="h5">Level {props.id}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <PeopleOutlineIcon sx={{ mr: 1 }} />
          <Typography sx={{ mr: 1 }}>0</Typography>
          <img src={IconImage} alt="IconImage" style={{ width: '20px', marginRight: '.5em' }} />
          <Typography>0</Typography>
        </Box>
      </Box>
      <ArrowForwardIosIcon sx={{ border: '1px solid black', p: 0.5, borderRadius: 10 }} />
    </Box>
  )
}

export default function TeamLevel() {
  return (
    <Box>
      {[...Array(10)].map((item, index) => {
        return <Level id={index + 1} />
      })}
    </Box>
  );
}