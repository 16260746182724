import axios from "axios";
import { GET_ERRORS, GET_PROPERTIES, GET_TOKEN, GET_GIFT } from "../types";

// Get settings
export const getSettings = () => (dispatch) => {
  axios
    // .get('http://localhost:5000/api/setting')
    .get("https://api.gold-bit.io/api/setting")
    .then((res) =>
      dispatch({
        type: GET_PROPERTIES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getTokenInfo = () => (dispatch) => {
  axios
    // .get('http://localhost:5000/api/token')
    .get("https://api.gold-bit.io/api/token")
    .then((res) =>
      dispatch({
        type: GET_TOKEN,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getGiftInfo = () => (dispatch) => {
  axios
    .get("https://api.gold-bit.io/api/setting/gift")
    .then((res) =>
      dispatch({
        type: GET_GIFT,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
