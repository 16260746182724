import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import whitepaper from "../../assets/gb_whitepaper.pdf";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export default function AboutUs() {
  const { t } = useTranslation();

  return (
    <>
      <a
        href={whitepaper}
        className="w-[280px] h-[80px] mx-auto font-bold text-[24px] text-white rounded-[30px] bg-[#FFCE00] flex items-center justify-center gap-3"
        target="_blank"
      >
        <DescriptionOutlinedIcon sx={{ width: 30, height: 30 }} />
        <span>Read Whitepaper</span>
      </a>
    </>
  );
}
