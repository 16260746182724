import { GET_PROPERTIES, GET_TOKEN, GET_GIFT } from '../types'

const initialState = {
  properties: null,
  token: null,
  gift: null,
  loading: false,
}

export default function propertyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
        loading: false,
      }
    case GET_TOKEN:
      return {
        ...state,
        token: action.payload,
        loading: false,
      }
    case GET_GIFT:
      return {
        ...state,
        gift: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
