import {
  GET_NETWORK_STATS,
  GET_STATEMENT_DETAIL,
  GET_USER_STATS,
} from '../types'

const initialState = {
  network: null,
  stat: null,
  details: null,
  loading: false,
}

export default function analyzeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NETWORK_STATS:
      return {
        ...state,
        network: action.payload,
        loading: false,
      }
    case GET_USER_STATS:
      return {
        ...state,
        stat: action.payload,
        loading: false,
      }
    case GET_STATEMENT_DETAIL:
      return {
        ...state,
        details: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
