import { GET_FAQS } from '../types'

const initialState = {
  faqs: null,
  loading: false,
}

export default function faqReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FAQS:
      return { ...state, faqs: action.payload, loading: false }
    default:
      return state
  }
}
