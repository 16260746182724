import axios from 'axios'
import { GET_ERRORS } from '../types'

export const withdraw = (data, navigate, swalWithBootstrapButton) => (dispatch) => {
  axios
    // .post('http://localhost:5000/api/account/send', data)
    .post('https://api.gold-bit.io/api/account/send', data)
    .then((res) => {
      swalWithBootstrapButton.fire(
        'Congratulations!',
        'Your tokens was withdrawn successfully!',
        'success',
      );
      navigate('/home')
    })
    .catch((err) => {
      const errors = err.response.data;

      if (errors && errors.send)
        if (errors.send === 'needDeposit')
          swalWithBootstrapButton.fire(
            'Warning!',
            "You must deposit and stake WHT tokens before you withdraw earning tokens.",
            'warning',
          ); else
          swalWithBootstrapButton.fire(
            'Warning!',
            errors.send,
            'warning',
          )
    }
    )
}
