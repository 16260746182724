import * as React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import PersonIcon from '@mui/icons-material/Person'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useTranslation } from "react-i18next"
import { SwalAlert } from '../../swal/SwalAlert'
import 'sweetalert2/src/sweetalert2.scss'

import { getUserById } from '../../redux/actions/userActions'

function Password(props) {
  return (
    <Box sx={{ mt: 2 }}>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <Input
        margin="dense"
        required
        fullWidth
        id={props.id}
        name={props.id}
        value={props.value}
        autoComplete={props.id}
        startAdornment={props.startAdornment}
        endAdornment={
          props.endAdornment ? (
            <InputAdornment onClick={() => {
              SwalAlert.fire(
                'Copied!',
                'Referral code was copied',
                'success',
              )
              navigator.clipboard.writeText(props.value)
            }} position="end">
              <IconButton aria-label="toggle password visibility">
                <ContentCopyIcon color="primary" />
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          )
        }
        sx={{ mt: 1 }}
      />
    </Box>
  )
}

const Profile = ({ getUserById, users }) => {
  const { t } = useTranslation()
  const { user } = users

  React.useEffect(() => {
    getUserById()
  }, [getUserById])

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    })
  }

  return (
    <>
      <Typography
        component="h1"
        variant="h5"
        align="center"
        color="text.secondary"
      >
        {t('profile_details')}
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        {/* <Password id="current-password" label="Phone Number" value="+9887870893" endAdornment={false} /> */}
        <Password
          id="new-password"
          label={t('label_email')}
          value={user && user.email}
          startAdornment={
            <InputAdornment position="start">
              <EmailIcon color="primary" />
            </InputAdornment>
          }
          endAdornment={false}
        />
        <Password
          id="new-password"
          label={t('your_name')}
          value={user && user.firstName + user.lastName}
          startAdornment={
            <InputAdornment position="start">
              <PersonIcon color="primary" />
            </InputAdornment>
          }
          endAdornment={false}
        />
        <Password
          id="new-password"
          label={t('label_username')}
          value={user && user.username}
          startAdornment={
            <InputAdornment position="start">
              <AdminPanelSettingsIcon color="primary" />
            </InputAdornment>
          }
          endAdornment={false}
        />
        <Password
          id="new-password"
          label={t('label_refCode')}
          value={user && user.code ? `https://gold-bit.io/signup?ref=${user.code}` : '********'}
          endAdornment={true}
        />
      </Box>
    </>
  )
}

Profile.propTypes = {
  getUserById: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  users: state.users,
})

export default connect(mapStateToProps, { getUserById })(Profile)
