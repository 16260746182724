import * as React from "react";
import { Box, Typography } from "@mui/material";
import NoResultImage from "../../assets/images/no_result.png";
import { useTranslation } from "react-i18next";
import video from "../../assets/news.mp4";

export default function News() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 1,
      }}
    >
      <video
        src={video}
        className="w-full h-full rounded-xl"
        autoPlay
        loop
        muted
      />
      {/* <img src={NoResultImage} className="w-[300px]" alt="No Result" />
      <Typography variant="h4" color="text.third">
        {t("empty_title")}
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }} align="center">
        {t("empty_info")}
      </Typography> */}
    </Box>
  );
}
