import * as React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { SwalAlert } from '../../swal/SwalAlert'
import 'sweetalert2/src/sweetalert2.scss'

import { Button, Box, Divider, Typography } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import IconImage from '../../assets/images/icon.png'

import { getUserById } from '../../redux/actions/userActions'
import { getTokenInfo } from '../../redux/actions/propertyActions'
import { useTranslation } from 'react-i18next'

const shortenAddress = (address, chars) => {
  return `${address.slice(0, chars)}...${address.slice(-chars)}`
}

const Wallet = ({
  getUserById,
  getTokenInfo,
  handleWithdrawOpen,
  users,
  settings,
}) => {
  const { t } = useTranslation()
  const { user } = users
  const { token } = settings

  React.useEffect(() => {
    getUserById()
    getTokenInfo()
  }, [getUserById, getTokenInfo])

  return (
    <Box>
      <Typography sx={{ mt: 2 }} color="text.secondary">
        {t('label_wallet')}
      </Typography>
      <Typography
        sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}
        color="text.secondary"
      >
        <AccountBalanceWalletIcon color="primary" sx={{ mr: 1 }} />
        <Typography className="xs-none md-block">
          {user && user.walletBep}
        </Typography>
        <Typography className="xs-block md-none">
          {user && shortenAddress(user.walletBep, 5)}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <ShareIcon
            color="primary"
            sx={{ ml: 1 }}
            className="cursor-pointer"
          />
          <ContentCopyIcon
            color="primary"
            sx={{ ml: 1 }}
            className="cursor-pointer"
            onClick={() => {
              SwalAlert.fire(
                'Copied!',
                'Wallet address was copied',
                'success',
              )
              navigator.clipboard.writeText(user ? user.walletBep : '')
            }}
          />
        </Box>
      </Typography>
      <Divider variant="middle" sx={{ marginTop: 2 }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 6,
        }}
      >
        <Box>
          <Typography align="center" color={"#ffce00"}>
            {user && Number(user.wht_balance_gb).toFixed(3)}
          </Typography>
          <Typography align="center">
            WHT
            <br />
            {t('balance')}
          </Typography>
          <br />
          <Typography align="center" color={"#ffce00"}>
            {user && Number(user.balance_gb).toFixed(2)}
          </Typography>
          <Typography align="center">
            USDT
            <br />
            {t('balance')}
          </Typography>
        </Box>
        <img
          src={IconImage}
          alt="icon"
          style={{ width: '150px', height: '130px' }}
        />
        <Box>
          <Typography align="center" color={"#ffce00"}>${token && token.curPrice}</Typography>
          <Typography align="center">
            WHT
            <br />
            {t('price')}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 20 }}>
        <Button
          variant="contained"
          sx={{ mt: 3, mb: 2, minWidth: '150px', color: 'black' }}
          className="button2"
          onClick={handleWithdrawOpen}
        >
          {t('withdraw')}
        </Button>
        <a href="http://white-light.io" target="_blank" rel="noreferrer">
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2, minWidth: '150px', color: 'black' }}
            className="button1"
          >
            {t('deposit')}
          </Button>
        </a>
      </Box>
    </Box>
  )
}

Wallet.propTypes = {
  getUserById: PropTypes.func.isRequired,
  getTokenInfo: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  users: state.users,
  settings: state.settings,
})

export default connect(mapStateToProps, { getUserById, getTokenInfo })(Wallet)
