import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from '../components/Spinner'

const PrivateRoute = ({
  component: Component,
  handleDepositOpen,
  handleWithdrawOpen,
  auth: { isAuthenticated, loading },
}) => {
  if (loading) return <Spinner />
  if (isAuthenticated) {
    if (handleDepositOpen && handleWithdrawOpen)
      return (
        <Component
          handleDepositOpen={handleDepositOpen}
          handleWithdrawOpen={handleWithdrawOpen}
        />
      )
    else return <Component />
  }

  return <Navigate to="/signin" />
}

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(PrivateRoute)
