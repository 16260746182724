export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_STAKING_REWARDS = "GET_STAKING_REWARDS";
export const GET_REFERRAL_BONUS = "GET_REFERRAL_BONUS";
export const GET_BALANCE = "GET_BALANCE";
export const GET_PROPERTIES = "GET_PROPERTIES";
export const GET_FAQS = "GET_FAQS";
export const GET_USER = "GET_USER";
export const GET_TOKEN = "GET_TOKEN";
export const GET_USER_STATS = "GET_USER_STATS";
export const GET_NETWORK_STATS = "GET_NETWORK_STATS";
export const GET_STATEMENT_DETAIL = "GET_STATEMENT_DETAIL";
export const GET_TEAM_INFO = "GET_TEAM_INFO";
export const GET_TEAM_TASK = "GET_TEAM_TASK";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_CODES = "GET_CODES";
export const GET_GIFT = "GET_GIFT";