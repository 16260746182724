import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withRouter } from "../../utils/withRouter";

import {
  IconButton,
  Box,
  Typography,
  InputAdornment,
  Button,
  Input,
  InputLabel,
  Link,
  Checkbox,
  FormControlLabel,
  Modal,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import { Info } from "@mui/icons-material";
import {
  ContactSupportRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import { registerUser } from "../../redux/actions/authActions";

const shortenAddress = (address, chars) => {
  if (address) return `${address.slice(0, chars)}...${address.slice(-chars)}`;
};

function Password(props) {
  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      {props.id === "referralCode" ? (
        <Input
          margin="dense"
          required
          fullWidth
          type={
            props.type !== "password"
              ? props.type
              : showPassword
              ? "password"
              : "text"
          }
          id={props.id}
          name={props.id}
          placeholder={props.placeholder}
          value={props.value}
          autoComplete={props.id}
          startAdornment={
            <InputAdornment position="start">
              {props.startAdornment}
            </InputAdornment>
          }
          endAdornment={
            props.endAdornment ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : (
              <></>
            )
          }
          sx={{ mt: 1 }}
        />
      ) : (
        <Input
          margin="dense"
          required
          fullWidth
          type={
            props.type !== "password"
              ? props.type
              : showPassword
              ? "password"
              : "text"
          }
          id={props.id}
          name={props.id}
          placeholder={props.placeholder}
          autoComplete={props.id}
          startAdornment={
            <InputAdornment position="start">
              {props.startAdornment}
            </InputAdornment>
          }
          readOnly={props.id === "referralCode" ? true : false}
          endAdornment={
            props.endAdornment ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : (
              <></>
            )
          }
          sx={{ mt: 1 }}
        />
      )}
    </Box>
  );
}

const SignUp = ({ registerUser, auth, errors, router }) => {
  const { t } = useTranslation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const ref = query.get("ref");

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [privateKey, setPrivateKey] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [warning, setWarning] = React.useState("");
  const [copied, setCopyStatus] = React.useState(null);
  const [refCode, setRefCode] = React.useState("");

  React.useEffect(() => {
    if (ref && ref !== "") {
      setRefCode(ref);
    }
  }, [ref]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const { isAuthenticated } = auth;
  if (isAuthenticated) return <Navigate to={"/home"} />;

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    router.navigate("/signin");
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!checked) {
      setWarning("Please agree to our Terms and Conditions");
      return;
    }
    const data = new FormData(event.currentTarget);

    const userData = {
      email: data.get("email"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      username: data.get("username"),
      password: data.get("password"),
      password2: data.get("password2"),
      referralCode: data.get("referralCode"),
    };

    registerUser(userData, handleOpen, setPrivateKey, router.navigate);
  };

  const handleCopyPK = (e) => {
    e.preventDefault();

    setCopyStatus("Copied");
  };

  return (
    <>
      <Typography
        component="h1"
        variant="h5"
        align="center"
        color="text.secondary"
      >
        Create your own account
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Password
          id="email"
          type="email"
          label={t("label_email")}
          placeholder={t("ph_email")}
          startAdornment={<EmailIcon color="primary" />}
          endAdornment={false}
        />
        {errors && errors.email && (
          <p style={{ color: "red", fontSize: "13px" }}>{errors.email}</p>
        )}
        <Password
          id="firstName"
          type="text"
          label={t("label_fName")}
          placeholder={t("ph_fName")}
          startAdornment={<EmailIcon color="primary" />}
          endAdornment={false}
        />
        {errors && errors.firstName && (
          <p style={{ color: "red", fontSize: "13px" }}>{errors.firstName}</p>
        )}
        <Password
          id="lastName"
          type="text"
          label={t("label_lName")}
          placeholder={t("ph_lName")}
          startAdornment={<EmailIcon color="primary" />}
          endAdornment={false}
        />
        {errors && errors.lastName && (
          <p style={{ color: "red", fontSize: "13px" }}>{errors.lastName}</p>
        )}
        <Password
          id="username"
          label={t("label_username")}
          type="text"
          placeholder={t("ph_username")}
          startAdornment={<PersonIcon color="primary" />}
          endAdornment={false}
        />
        {errors && errors.username && (
          <p style={{ color: "red", fontSize: "13px" }}>{errors.username}</p>
        )}
        <Password
          id="password"
          type="password"
          label={t("label_password")}
          placeholder={t("ph_password")}
          startAdornment={<LockIcon color="primary" />}
          endAdornment={true}
        />
        {errors && errors.password && (
          <p style={{ color: "red", fontSize: "13px" }}>{errors.password}</p>
        )}
        <Password
          id="password2"
          label={t("label_cfPassword")}
          type="password"
          placeholder={t("ph_cfPassword")}
          startAdornment={<LockIcon color="primary" />}
          endAdornment={true}
        />
        {errors && errors.password2 && (
          <p style={{ color: "red", fontSize: "13px" }}>{errors.password2}</p>
        )}
        <Password
          id="referralCode"
          type="text"
          label={t("label_refCode")}
          value={refCode}
          placeholder={t("ph_refCode")}
          startAdornment={<PersonIcon color="primary" />}
          endAdornment={false}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onClick={(e) => setChecked(!checked)}
              name="remember"
              color="primary"
            />
          }
          label={
            <div className="flex gap-2">
              <p>
                By clicking this for Sign Up, you are agreed to our &nbsp;
                <strong
                  className="text-[#FFCE00]"
                  onClick={() => setOpen2(true)}
                >
                  Terms and Conditions
                </strong>
              </p>
            </div>
          }
          sx={{ mt: 2, zIndex: 1 }}
        />
        {!checked && (
          <p style={{ color: "red", fontSize: "13px" }}>{warning}</p>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 5 }}
          className="button1"
        >
          {t("register_title")}
        </Button>
        <Typography align="center" sx={{ mt: 5, mb: 5 }}>
          {t("already_account")}&nbsp;
          <Link href="/signin">{t("login_title")}</Link>
        </Typography>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        disableBackdropClick
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("privateKey_info")}
          </Typography>
          <div className="flex gap-2 items-center justify-center">
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {shortenAddress(privateKey, 5)}
            </Typography>
            <CopyToClipboard text={privateKey}>
              <img
                onClick={handleCopyPK}
                className="w-5 h-5 cursor-pointer bg-slate-500 mt-2"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC9SURBVHgB7ZWhDsIwEIb/LhWgZ5mFZwCJ5xnwvBB2PAMaPYueBTsLsvwnICWhWS9pEUu/5F8vu6b/9dK0QGbMO3DO1Rx20POkzsaYx69k5cUNtRYvpWbUAgGsF8/lw0pOSIgNJdgyqaqBDmnXjUUOowbkQNXQ01FtjIEs3rKaDpFw13t4B0eokJliUAyKwdQMBmTgY8BLredwRGK+WkSTKxJjR/IbXsErxLOkev+HCc3kwlvoXzThwk7c8S9eof8soAC+q+gAAAAASUVORK5CYII="
                alt="copy"
              />
            </CopyToClipboard>
          </div>
          {copied && (
            <p
              className="text-center"
              style={{ color: "red", fontSize: "13px" }}
            >
              {copied}
            </p>
          )}
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        disableBackdropClick
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          className="w-[300px] md:w-[500px] xl:w-[800px] h-full overflow-scroll"
        >
          <div className="flex flex-col space-y-3 items-start justify-center text-[12px] md:text-base">
            <p>Terms of Use</p>
            <p>GOLD-BIT</p>
            <p>2023-03-07 05:00</p>
            <p>DEAR GOLDBITS</p>
            <p>Please read the Terms of Use carefully</p>
            <p>
              GoldBit Limited (hereinafter referred to as the 'Company') is a
              company incorporated in Seychelles under the laws of Seychelles.
              It is a platform for users to make digital asset transactions and
              provide related services (hereinafter referred to as the
              “service”). Company, https://gold-bit.io/signup the website
              (hereinafter referred to as "this website" or "website"). For the
              convenience of this Agreement, the Company and the Website
              collectively use "we" or other first-party names in this
              Agreement. As long as the natural person or other subject visiting
              the Site is a user of this site, "you" or another second person
              will be used to facilitate this Agreement. For convenience in this
              Agreement, we and you in this Agreement shall collectively be
              referred to as the “party” and we or you shall be referred to as
              the “Party”. All content of this website is intended for the
              convenience of users and can be found in multiple languages. In
              case of conflict or negligence, the content in English will be
              valid.
            </p>
            <p>General Provisions</p>
            <p>
              1. The User Agreement (hereinafter referred to as "this Agreement"
              or "these terms and conditions") consists of the main body, the
              Privacy Terms, the Understanding of Your Customer and the
              Anti-Money Laundering Policy, as well as any rules, statements,
              instructions, etc. that this Website may publish or publish in the
              future.
            </p>
            <p>
              2. Before using the services offered by this Website, you should
              read this Agreement carefully and consult a professional lawyer if
              you have any doubts or may otherwise be necessary. If you do not
              agree to the terms and conditions of this Agreement and/or any
              changes to them from time to time and at any time, please stop
              using the service provided by this Website immediately or stop
              accessing to this Website. By accessing the Website or using any
              service or other similar transactions produced by the Website, you
              are deemed to have understood and fully accepted by all the terms
              and conditions of the Agreement, including any changes,
              regulations or alterations proposed by the Website from time to
              time and at any time in this Agreement.
            </p>
            <p>
              3. After filling in the relevant information in accordance with
              the requirements of this Website and passing other relevant
              procedures, you will successfully register as a member of this
              Website (hereinafter referred to as the "Member"); if you click
              the "I Agree" button in the registration process, you will be
              deemed to have reached an agreement with the Company with the
              electronic signature; or when you use this Website, clicking the
              "I Agree" button or a similar button, or if you use the services
              offered by this Website in any manner permitted by this Website,
              you will be deemed to have fully understood all the terms and
              conditions under this Agreement, and in this case your absence of
              a manual signature, this Agreement may have upon you. Will not
              affect legal binding.
            </p>
            <p>
              4. After you become a member of this Website, you will receive a
              member account and relevant password that must be properly stored
              by you as a member of this Website; Members are responsible for
              all activities and events performed through their accounts.
            </p>
            <p>
              5. If you are not a member of this Website, you may not trade on
              the digital asset trading platform provided by this Website and
              you may not access member-specific services in accordance with the
              rules and regulations of this Website; you may only log in to
              browse the Website and access other services permitted by the
              terms and regulations of this Website.
            </p>
            <p>
              6. When you register as a member of this Website and use any of
              the services and functions offered by this Website, you have read,
              understood this Agreement and:
            </p>
            <p>
              1) You agree to be subject to all terms and conditions of the
              agreement;
            </p>
            <p>
              2) You verify that you have reached another legal age and that you
              have registered up on this Website, purchased or selled through
              this Website, that you have provided information on this Website,
              and that you have accepted the Services provided by this Website,
              and that you have accepted the services provided by this Website,
              are in accordance with the relevant laws and regulations of the
              sovereign state or territory that has jurisdiction over you, and
              that you have sufficient capacity to accept these terms and
              conditions, enter into transactions, and use this Website for
              digital asset transactions.
            </p>
            <p>
              3) You undertake that all your digital assets included in the
              transactions within this scope are legally owned and owned by you.
            </p>
            <p>
              4) You agree to assume any liability for your own transaction and
              non-transaction activities and all profits and losses arising from
              them.
            </p>
            <p>
              5) You confirm that the information you provide during
              registration is real and accurate.
            </p>
            <p>
              6) You agree to comply with all applicable laws, including
              reporting any transaction earnings for tax purposes.
            </p>
            <p>
              7) This Agreement is binding only on the rights and obligations
              between you and us and does not cover legal relations and legal
              disputes arising out of and related to the transactions of digital
              assets between the users of this Website and other websites and
              you.
            </p>
            <p>Changes to this Agreement</p>
            <p>
              We reserve the right to make changes to this Agreement from time
              to time and to post this change on the Website without further
              notice to you about your rights. The date of the change is
              indicated on the first page of the amended contract. The amended
              agreement will take effect immediately after it is announced on
              the Website. From time to time, you will browse this Website and
              track information about the time and content of changes made to
              this Agreement, if any. If you do not accept the changes, you will
              immediately cease using the services offered by this Website; if
              you continue to use the services offered by this Website, you will
              be deemed to have accepted and accepted the amended agreement.
            </p>
            <p>Record</p>
            <p>Eligibility for Registration</p>
            <p>
              When you complete the registration process or use the services
              provided by this Website in any other way permitted by this
              Website, you acknowledge and warrant that you are a natural
              person, legal entity or other entity authorized to sign this
              Agreement and use the services of this Website as provided by
              applicable law. When you click the button indicating that you
              agree to register, you or your authorized representative will be
              deemed to have accepted the content of this Agreement, and your
              authorized representative will be deemed to register on this
              Website and will use the services offered by this Website on your
              behalf. If you are not a natural person, legal entity or entity
              with the above-mentioned competence, you and your authorized
              representative will undertake all the consequences of this and the
              company reserves the right to cancel or permanently freeze your
              account and to hold you and your authorities responsible.
            </p>
            <p>
              In the meantime, by accessing and using the services, you
              represent and warrant that you are not on the FATF (Financial
              Action Task Force) list or the “Specially Designated National”
              list set by the OFAC (U.S. Department of the Treasury Foreign
              Assets Control Office) or other commercial or economic sanctions
              lists, such as the UN Security Council Sanctions list. We reserve
              the right to choose the markets and jurisdictions in which we will
              operate and may, in its sole discretion, restrict or reject the
              Services in certain countries.
            </p>
            <p>Purpose of Registration</p>
            <p>
              You acknowledge and warrant that you are not registered on this
              Website for the purpose of violating any of the applicable laws or
              regulations, or to disrupt the order of digital asset transactions
              on this Website.
            </p>
            <p>Registration Agreement</p>
            <p>
              1. You agree to provide a valid email address, a mobile phone
              number, and other information that meets the requirements on the
              user registration page of this Website. You may use the email
              address, mobile phone number, or any other method permitted by
              this Website to log in to this Website. Where necessary and in
              accordance with the requirements of applicable laws and
              regulations of the relevant jurisdictions, you will provide your
              real name, ID card and other information required by applicable
              laws, regulations, Privacy Terms and anti-money laundering
              conditions, and you will continuously update your registration
              data to be timely, detailed and accurate as required. All original
              data written will be referenced as registration information. You
              will be responsible for the authenticity, integrity and accuracy
              of such information and you will have any direct or indirect loss
              and negative consequences arising from it.
            </p>
            <p>
              2. If any of the applicable laws, regulations, rules, orders and
              other regulatory documents of your sovereign country or region
              require your mobile phone accounts to be based on real names, you
              confirm that the mobile phone number you provide for registration
              purposes has passed the real name registration procedure. If you
              are unable to provide the requested mobile phone number, any
              direct or indirect losses and negative consequences arising from
              this and affecting you will be covered by you.
            </p>
            <p>
              3. After providing the necessary registration information in a
              legal, complete and valid manner and passing this information
              through the relevant verification, you will have the right to
              obtain an account and password on this Website. After receiving
              this account and password, your registration will be considered
              successful and you will be able to log in as a member of this
              Website.
            </p>
            <p>
              4. You agree to receive e-mails and/or text messages sent by this
              Website regarding the administration and operation of this
              Website.
            </p>
            <p>5. After completing your public user registration…</p>
          </div>
        </Box>
      </Modal>
    </>
  );
};

SignUp.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(SignUp));
