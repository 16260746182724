import axios from 'axios'
import {
  GET_USER_STATS,
  GET_NETWORK_STATS,
  GET_STATEMENT_DETAIL,
  GET_ERRORS,
} from '../types'

export const getNetworkStats = () => (dispatch) => {
  axios
    // .get('http://192.168.111.42:5000/api/analyze')
    .get('https://api.gold-bit.io/api/analyze')
    .then((res) =>
      dispatch({
        type: GET_NETWORK_STATS,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }),
    )
}

export const getStatsByUser = () => (dispatch) => {
  axios
    // .get('http://192.168.111.42:5000/api/analyze/by-user')
    .get('https://api.gold-bit.io/api/analyze/by-user')
    .then((res) =>
      dispatch({
        type: GET_USER_STATS,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }),
    )
}

export const getStatementDetails = () => (dispatch) => {
  axios
    // .get('http://192.168.111.42:5000/api/analyze/detail')
    .get('https://api.gold-bit.io/api/analyze/detail')
    .then((res) =>
      dispatch({
        type: GET_STATEMENT_DETAIL,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_STATEMENT_DETAIL,
        payload: null,
      }),
    )
}
