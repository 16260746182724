import * as React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { getFaqs } from '../../redux/actions/faqActions'

const FAQs = ({ getFaqs, faq }) => {
  const { faqs } = faq
  const [expanded, setExpanded] = React.useState(false)

  React.useEffect(() => {
    getFaqs()
  }, [getFaqs])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  let faqsContent

  if (faqs && faqs.length > 0) {
    faqsContent = faqs.map((item, idx) => {
      return (
        <Accordion
          expanded={expanded === `panel${idx}`}
          onChange={handleChange(`panel${idx}`)}
          sx={{ mt: 2 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${idx}bh-content`}
            id={`panel${idx}bh-header`}
          >
            <Typography>{localStorage.getItem('i18nextLng') === 'en' ? item.title : item.title2}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="text.secondary">{localStorage.getItem('i18nextLng') === 'en' ? item.description : item.description2}</Typography>
          </AccordionDetails>
        </Accordion>
      )
    })
  }

  return <>{faqsContent}</>
}

FAQs.propTypes = {
  getFaqs: PropTypes.func.isRequired,
  faq: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  faq: state.faq,
})

export default connect(mapStateToProps, { getFaqs })(FAQs)
