import React from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";

const ProgressCountdown = ({ base, deadline, hideBar, description }) => {
  const percentage =
    Date.now() >= deadline.getTime()
      ? 100
      : ((Date.now() - base.getTime()) /
          (deadline.getTime() - base.getTime())) *
        100;

  const countdownRenderer = (countdownProps) => {
    const { days, hours, minutes, seconds } = countdownProps;
    const h = String(days * 24 + hours);
    const m = String(minutes);
    const s = String(seconds);
    return (
      <div className="font-bold">
        {h.padStart(2, "0")}:{m.padStart(2, "0")}:{s.padStart(2, "0")}
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-center items-center h-full text-[16px] font-[600]">
      <Countdown
        key={new Date().getTime()}
        date={deadline}
        renderer={countdownRenderer}
      />
      {hideBar ? (
        ""
      ) : (
        <div className="w-full h-[8px] rounded-[3px] bg-[#fff]">
          <div
            className="h-full rounded-[3px] bg-black"
            style={{ width: percentage + "%" }}
          />
        </div>
      )}
    </div>
  );
};

export default ProgressCountdown;
