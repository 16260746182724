import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import NoResultImage from "../../assets/images/no_result.png";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { getNotifications } from "../../redux/actions/notificationActions";

import { useTranslation } from 'react-i18next'

const Notification = ({ getNotifications, notification }) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const { notifications } = notification;

  let notifications_;
  if (notifications && notifications.length > 0) {
    notifications_ = notifications.map((item, idx) => {
      return (
        <div
          key={idx}
          className="flex flex-col border-[1px] rounded-[8px] bg-white p-3 w-full"
        >
          <div className="flex">
            <NotificationsIcon color="primary" />
            <span className="text-[18px] font-[500] pl-4">{item.title}</span>
          </div>
          <div className="text-[14px] font-[500] text-gray-700 pt-3">{item.content}</div>
        </div>
      );
    });
  }
  else if (notifications && notifications.length === 0) {
    notifications_ = (
      <>
        <img src={NoResultImage} className="w-[300px]" alt="No Result" />
        <Typography variant="h4" color="text.third">
          {t('empty_title')}
        </Typography>
        <Typography variant="h5" sx={{ mt: 2 }} align="center">
          {t('empty_info')}{' '}{t('empty_info2')}
        </Typography>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      className="mb-4"
    >
      {notifications_}
    </Box>
  );
};

Notification.propTypes = {
  getNotifications: PropTypes.func.isRequired,
  notifications: PropTypes.object,
};

const mapStateToProps = (state) => ({
  notification: state.notification,
});

export default connect(mapStateToProps, { getNotifications })(Notification);
