export default {
    login_title: 'Sign In',
    login_info: "Let's Start to Earn WHT",
    label_email: 'Email Address',
    ph_email: 'Enter your Email Address',
    label_password: 'Password',
    ph_password: 'Enter your password',
    remember_me: 'Remember me',
    forgot_password: 'Forget Password?',
    have_account: "Don't have an account?",
    register_title: 'Sign Up',
    label_fName: 'First Name',
    ph_fName: 'Enter your First Name',
    label_lName: 'Last Name',
    ph_lName: 'Enter your Last Name',
    label_username: 'Username',
    ph_username: 'Enter your Username',
    label_cfPassword: 'Confirm Password',
    ph_cfPassword: 'Retype password',
    label_refCode: 'Referral Code',
    ph_refCode: 'Enter your Referral Code',
    agree_term:
        'By clicking this for Sign Up, you are agreed to our Terms and Conditions',
    already_account: 'Already have an account?',
    privateKey_info:
        "Here is your private key. Please save this key and don't share it. It used to sign in your account.",
    balance: 'Balance',
    price: 'Price',
    add_referral: 'Add Referral',
    stake_wht: 'Stake WHT',
    tap: 'Tap to claim your reward',
    days: 'days',
    rewards: 'Rewards',
    staking: 'Staking',
    referral: 'Referral',
    withdraw: 'Withdraw',
    deposit: 'Deposit',
    menu_home: 'Home',
    menu_statistics: 'Statistics',
    menu_team: 'Team',
    menu_news: 'News',
    news_title: 'News & Updates',
    empty_title: "It's Empty",
    empty_info: "Hmm.. looks like you don't",
    empty_info2: 'have any statements',
    notification_title: 'Notifications',
    menu2_profile: 'My Profile',
    menu2_bonus: 'My Bonus',
    you_earned: 'You earned',
    menu2_cp: 'Change Password',
    menu2_wallet: 'Wallet',
    menu2_privateKey: 'Private Key',
    menu2_about: 'About',
    menu2_promotions: 'Promotions',
    menu2_faq: "FAQ's",
    menu2_support: 'Support',
    menu2_pdf: 'CyberGold Network PDF',
    menu2_connect: 'Connect with us',
    menu2_logout: 'Logout',
    profile_title: 'Profile',
    profile_details: 'Profile Details',
    your_name: 'Your Name',
    cp_caption: 'Change Your Password',
    label_cp: 'Current Password',
    ph_cp: 'Enter your current password',
    label_np: 'New Password',
    label_rp: 'Retype New Password',
    btn_change: 'Change',
    your_wallet: 'Your Wallet',
    label_wallet: 'Your Wallet Address',
    modal_dc: 'Available Asset to deposit',
    modal_di: 'Deposit WHT tokens for staking.',
    modal_di2: 'Please move to cyber-bit.io and purchase WHT token.',
    modal_wc: 'Choose Asset to withdraw',
    modal_wi: 'The network fees for transactions on BSC Network',
    btn_confirm: 'Confirm',
    label_addr: 'Address',
    label_amt: 'Amount',
    ph_addr: 'Enter your Wallet Address',
    ph_amt: 'Enter your Amount',
    available_withdrawal: 'Available for Withdrawal',
    about_title: 'About Us',
    gb_network: 'CyberGold Network',
    gb_token: 'CyberGold Token',
    gb_info:
        'CyberGold Network is a reward distribution platform developed for smartphone and pc users. The platform uses contracts tht enable users to lock their WHT Tokens and earn daily rewards & team rewards.',
    gb_info2:
        'WHT token is a digital asset / community token. The initial supply of WHT token is 20 Billion GB tokens. The supply distribution will initiate through a 3-phase ITO (Initial Token Offering). The total initial supply will be 3 Trillion WHT tokens with a burning mechanism designed to burn 10% supply every month.',
    label_support: 'How can we help?',
    ph_subject: 'subject',
    label_description: 'Description',
    ph_description: 'Hi let us know how can we help you...',
    btn_submit: 'Submit',
    connect_title: 'Connect With Us',
    total_team: 'Total Members',
    active_team: 'Active members',
    inactive_team: 'Inactive members',
    direct_members: 'Direct Members',
    team_level: 'Team Level',
    team_levels: 'Team Levels',
    lable_level: 'Level',
    your_stats: 'YOUR STATS',
    network_stats: 'NETWORK STATS',
    my_team: 'My Team',
    my_staking: 'My Staking',
    my_rewards: 'My Rewards',
    receivable_rewards: 'Receivable Rewards',
    my_withdrawals: 'My Withdrawals',
    available_reward: 'Available Reward',
    statement_details: 'Statement Details',
    your_rewards: 'Your Rewards',
    last_5days: 'Last 5 days',
    last_10days: 'Last 10 days',
    custom: 'Custom',
    total_participants: 'Total Participants',
    total_staked: 'Total Staked in Pool',
    total_rewards: 'Total Rewards',
    total_withdrawals: 'Total Withdrawals',
    top_stakers: 'Top Stakers & Earners',
    pk_caption: 'Save it somewhere safe and secret',
    pk_info:
        'Warning: Never disclose this private key. Anyone with your private key can fully control your wallet including transferring away your WHT.',
    copy_pk: 'Copy Private Key',
};
