import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import stakeReducer from "./stakeReducer";
import propertyReducer from "./propertyReducer";
import faqReducer from "./faqReducer";
import userReducer from "./userReducer";
import analyzeReducer from "./analyzeReducer";
import teamReducer from "./teamReducer";
import notificationReducer from "./notificationReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  staking: stakeReducer,
  settings: propertyReducer,
  faq: faqReducer,
  users: userReducer,
  stats: analyzeReducer,
  teams: teamReducer,
  notification: notificationReducer,
});
