import axios from "axios";
import jwt_decoded from "jwt-decode";
import setAuthToken from "../../utils/setAuthToken";

import { GET_CODES, GET_ERRORS, SET_CURRENT_USER, CLEAR_ERRORS } from "../types";

// Login
export const loginUser = (userData, handleOpen, setToken) => (dispatch) => {
  dispatch(clearErrors());
  axios
    // .post("http://localhost:5000/api/users/login", userData)
    .post("https://api.gold-bit.io/api/users/login", userData)
    .then((res) => {
      const { token, isOldDevice } = res.data;

      if (isOldDevice) {
        localStorage.setItem("token", token);
        setAuthToken(token);
        const decoded = jwt_decoded(token);
        dispatch(setCurrentUser({ decoded, token }));
      } else {
        setToken(token);
        handleOpen();
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Register
export const registerUser =
  (userData, handleOpen, setPrivateKey, navigate) => (dispatch) => {
    dispatch(clearErrors());
    axios
      // .post("http://localhost:5000/api/users/register", userData)
      .post("https://api.gold-bit.io/api/users/register", userData)
      .then((res) => {
        setPrivateKey(res.data && res.data.privateKey);
        handleOpen(true);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

// Verify Private Key
export const verifyUser = (userData, handleClose) => (dispatch) => {
  dispatch(clearErrors());
  axios
    // .post("http://localhost:5000/api/users/verify", userData)
    .post("https://api.gold-bit.io/api/users/verify", userData)
    .then((res) => {
      handleClose();

      const token = userData.token;
      localStorage.setItem("token", token);
      setAuthToken(token);
      const decoded = jwt_decoded(token);
      dispatch(setCurrentUser({ decoded, token }));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getCodes = () => (dispatch) => {
  dispatch(clearErrors());
  axios
    // .get("http://localhost:5000/api/sms/get-codes")
    .get("https://api.gold-bit.io/api/sms/get-codes")
    .then((res) =>
      dispatch({
        type: GET_CODES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const sendCode = (phoneData, setSMSStatus) => (dispatch) => {
  dispatch(clearErrors());
  axios
    // .post("http://localhost:5000/api/sms/send-code", phoneData)
    .post("https://api.gold-bit.io/api/sms/send-code", phoneData)
    .then((res) => {
      setSMSStatus(true);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const resendCode = (phoneData, setBtnStatus) => (dispatch) => {
  dispatch(clearErrors());
  setBtnStatus(true);
  axios
    // .post("http://localhost:5000/api/users/send-code", phoneData)
    .post("https://api.gold-bit.io/api/users/send-code", phoneData)
    .then((res) => {
      setTimeout(() => {
        setBtnStatus(false);
      }, 5000);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Set Current User Action
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Clear Errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
    payload: null,
  }
}

// LogOut Action
export const logOutUser = () => (dispatch) => {
  localStorage.removeItem("token");
  axios.get("https://api.gold-bit.io/api/users/logout");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  dispatch(clearErrors());
};
