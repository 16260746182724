import * as React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Box, Typography, Tab, Tabs } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import NoResultImage from '../../assets/images/no_result.png'
import IconImage from '../../assets/images/icon.png'

import { getStatementDetails } from '../../redux/actions/analyzeActions'

import DataTable, { createTheme } from 'react-data-table-component'

createTheme(
  'solarized',
  {
    text: {
      primary: 'black',
      secondary: 'gray',
    },
    background: {
      default: 'transparent',
    },
  },
  'dark',
)

function TabPanel(props) {
  const { children, value, index, label, ...other } = props
  if (label === 'REWARDS') {
    const { rewards, staking, referral } = props

    const rewards_cols = [
      {
        name: 'No',
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: 'Earned',
        selector: (row) => row.amt,
        sortable: true,
      },
      {
        name: 'When',
        selector: (row) => row.when,
        sortable: true,
      },
    ]

    const staking_data = [],
      referral_data = []
    if (staking && staking.length > 0) {
      let i = 0
      for (let item of staking) {
        let obj = {
          id: ++i,
          amt: item.earning,
          when: new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(new Date(item.created_at).getTime()),
        }
        staking_data.push(obj)
      }
    }

    if (referral && referral.length > 0) {
      let i = 0
      for (let item of referral) {
        let obj = {
          id: ++i,
          amt: item.amt,
          when: new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(new Date(item.created_at).getTime()),
        }
        referral_data.push(obj)
      }
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              color="text.secondary"
            >
              <Box>
                <Typography>Overall {label}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={IconImage} style={{ width: '16px' }} alt="" />
                  <Typography sx={{ ml: 2 }}>{rewards} WHT</Typography>
                </Box>
              </Box>
              <FilterListIcon
                color="primary"
                sx={{ border: '1px solid #444444', p: 0.1, borderRadius: 10 }}
              />
            </Box>
            {staking &&
            staking.length === 0 &&
            referral &&
            referral.length === 0 ? (
              <>
                <img
                  src={NoResultImage}
                  style={{ maxWidth: { xs: '300px', md: '300px' } }}
                  className="w-[300px] m-auto"
                  alt="No Result"
                />
                <Typography variant="h4" color="text.third" align="center">
                  It's Empty
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ mt: 2 }}
                  align="center"
                  color="text.secondary"
                >
                  Hmm.. looks like you don't have any statements
                </Typography>
              </>
            ) : (
              <>
                <DataTable
                  title="Staking History"
                  columns={rewards_cols}
                  data={staking_data}
                  pagination
                  theme="solarized"
                />
                <DataTable
                  title="Referral History"
                  columns={rewards_cols}
                  data={referral_data}
                  pagination
                  theme="solarized"
                />
              </>
            )}
          </Box>
        )}
      </div>
    )
  } else if (label === 'WITHDRAWALS') {
    const { withdrawals, total } = props

    const withdrawal_cols = [
      {
        name: 'No',
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: 'Amount',
        selector: (row) => row.amt,
        sortable: true,
      },
      {
        name: 'When',
        selector: (row) => row.when,
        sortable: true,
      },
    ]

    const withdrawal_data = []
    if (withdrawals && withdrawals.length > 0) {
      let i = 0
      for (let item of withdrawals) {
        let obj = {
          id: ++i,
          amt: item.amount,
          when: new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(new Date(item.created_at).getTime()),
        }
        withdrawal_data.push(obj)
      }
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              color="text.secondary"
            >
              <Box>
                <Typography>Overall {label}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={IconImage} style={{ width: '16px' }} alt="" />
                  <Typography sx={{ ml: 2 }}>
                    {total ? total : 0} WHT
                  </Typography>
                </Box>
              </Box>
              <FilterListIcon
                color="primary"
                sx={{ border: '1px solid #444444', p: 0.1, borderRadius: 10 }}
              />
            </Box>
            {withdrawals && withdrawals.length === 0 ? (
              <>
                <img
                  src={NoResultImage}
                  style={{ maxWidth: { xs: '300px', md: '300px' } }}
                  className="w-[300px] m-auto"
                  alt="No Result"
                />
                <Typography variant="h4" color="text.third" align="center">
                  It's Empty
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ mt: 2 }}
                  align="center"
                  color="text.secondary"
                >
                  Hmm.. looks like you don't have any statements
                </Typography>
              </>
            ) : (
              <>
                <DataTable
                  title="Withdrawal History"
                  columns={withdrawal_cols}
                  data={withdrawal_data}
                  pagination
                  theme="solarized"
                />
              </>
            )}
          </Box>
        )}
      </div>
    )
  } else {
    const { staking } = props

    const staking_cols = [
      {
        name: 'No',
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: 'Amount',
        selector: (row) => row.amt,
        sortable: true,
      },
      {
        name: 'When',
        selector: (row) => row.when,
        sortable: true,
      },
    ]

    const staking_data = []
    if (staking && staking.length > 0) {
      let i = 0
      for (let item of staking) {
        let obj = {
          id: ++i,
          amt: item.amount,
          when: new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(new Date(item.created_at).getTime()),
        }
        staking_data.push(obj)
      }
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              color="text.secondary"
            >
              <Box>
                <Typography>Overall {label}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={IconImage} style={{ width: '16px' }} alt="" />
                  <Typography sx={{ ml: 2 }}>
                    {staking && staking.length > 0 ? staking.amount : 0} WHT
                  </Typography>
                </Box>
              </Box>
              <FilterListIcon
                color="primary"
                sx={{ border: '1px solid #444444', p: 0.1, borderRadius: 10 }}
              />
            </Box>
            {staking && staking.length === 0 ? (
              <>
                <img
                  src={NoResultImage}
                  style={{ maxWidth: { xs: '300px', md: '300px' } }}
                  className="w-[300px] m-auto"
                  alt="No Result"
                />
                <Typography variant="h4" color="text.third" align="center">
                  It's Empty
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ mt: 2 }}
                  align="center"
                  color="text.secondary"
                >
                  Hmm.. looks like you don't have any statements
                </Typography>
              </>
            ) : (
              <>
                <DataTable
                  title="Current Staking"
                  columns={staking_cols}
                  data={staking_data}
                  pagination
                  theme="solarized"
                />
              </>
            )}
          </Box>
        )}
      </div>
    )
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const StatementDetails = (props) => {
  React.useEffect(() => {
    props.getStatementDetails()
  }, [])

  const { details } = props.stats

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab label="REWARDS" {...a11yProps(0)} />
          <Tab label="WITHDRAWALS" {...a11yProps(1)} />
          <Tab label="STAKING" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel
        value={value}
        index={0}
        label="REWARDS"
        rewards={details?.totalRewards}
        staking={details?.stakingRewards}
        referral={details?.referralRewards}
      />
      <TabPanel
        value={value}
        index={1}
        label="WITHDRAWALS"
        total={details?.totalWithdrawals}
        withdrawals={details?.withdrawals}
      />
      <TabPanel
        value={value}
        index={2}
        label="STAKING"
        staking={details?.staking}
      />
    </Box>
  )
}

StatementDetails.propTypes = {
  getStatementDetails: PropTypes.func.isRequired,
  stats: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  stats: state.stats,
})

export default connect(mapStateToProps, { getStatementDetails })(
  StatementDetails,
)
